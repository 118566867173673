<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {Icons} from "@/common/utils";
import constants from "@/common/constants";
import socketService from "@/modules/socket";
import {commonStore} from "@/stores";

const props = defineProps({
  zipId: {
    type: String,
  },
  zipName: {
    type: String,
  },
});

const status = ref("inprogress");

/**********************************************************
 * Methods
 *********************************************************/
onMounted(async () => {
  // 컴포넌트가 화면에 렌더링되면 소켓 서버에 연결
  socketService.onEvent("update_common", async (data) => {
    consoleLog("프로젝트 업데이트 이벤트 수신:", data);

    switch (data.event) {
      case constants.EVENT_TYPE.driveZipSuccess: {
        break;
      }
      case constants.EVENT_TYPE.driveZipFailure: {
        status.value = "fail";
        break;
      }
    }
  });
});
onUnmounted(() => {});

function onClose() {
  commonStore().removeZipDownload(props.zipId);
}
</script>

<template>
  <div class="zip-wrapper">
    <div class="min-w-[2.5rem]">
      <img class="w-9 h-9 m-auto" :src="Icons.IconZip" />
    </div>
    <div class="w-full ml-1">
      <p class="max-w-[11rem] cs-ellipsis1 text-md">{{ zipName }}</p>
      <div class="flex items-center justify-between w-full mt-1">
        <span class="text-xs" :class="'fail' === status ? 'text-red-600' : 'text-c-dark'">
          {{ "fail" === status ? "압축 실패" : "압축 진행중" }}</span
        >
      </div>
    </div>
    <button v-if="'fail' === status" class="" @click="onClose">
      <font-awesome-icon icon="xmark" class="text-lg" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.zip-wrapper {
  @apply flex items-center;
  @apply w-[18rem] mb-5 px-3 py-3;
  @apply rounded-lg border shadow-md bg-white;
}
</style>
