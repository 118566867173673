<script setup>
import {computed, ref} from "vue";

const props = defineProps({
  customCss: {
    type: String,
    default: null,
  },
  backgroundColor: {
    type: String,
    default: "",
  },
  round: {
    type: String,
    default: "0px",
  },
  size: {
    type: String,
    default: "1rem",
  },
  defaultIcon: {
    type: Boolean,
    default: true,
  },
  borderColor: {
    type: String,
    default: "",
  },
  borderWidth: {
    type: Number,
    default: 0,
  },
  imageUrl: {
    type: String,
  },
  imageSize: {
    type: String,
    default: "100%",
  },
});

const isLoaded = ref(false);

const url = computed(() => (props.imageUrl ? props.imageUrl : undefined));
</script>

<template>
  <div
    class="thumbnail"
    :class="customCss"
    :style="{
      backgroundColor: backgroundColor,
      borderRadius: round,
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
      borderWidth: borderWidth + 'px',
      borderColor: borderColor,
    }"
  >
    <template v-if="!isLoaded">
      <img v-if="defaultIcon" class="default-icon" />
      <div v-if="!defaultIcon" class="default-icon">
        <slot></slot>
      </div>
    </template>
    <img
      v-if="url"
      class="thumbnail_image"
      :style="{
        width: imageSize,
        height: imageSize,
      }"
      :src="url"
      @load="onload"
      onload="this.style.display='block';"
      onerror="this.style.display='none';"
    />
  </div>
</template>

<style lang="scss" scoped>
.thumbnail {
  overflow: hidden;
  position: relative;
  border-style: solid;

  &_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.default-icon {
  display: flex;
  position: absolute;
  // overflow: hidden;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
}
</style>
