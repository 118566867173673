<script setup>
import {onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {HttpStatusCode} from "axios";
import utils, {Icons} from "@/common/utils";
import {Modal, ModalComponents, CheckModule} from "@/modules";
import {groupStore, projectStore, submitStore} from "@/stores";
// Component
import {toast} from "vue3-toastify";
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import apis from "@/apis";

/**********************************************************
 * Reactive Variables
 *********************************************************/

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});

const historys = ref([]);
const isLoading = ref(true);

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(async () => {
  try {
    const res = await apis.project.getDownloadHistory(
      props.params.groupId,
      props.params.projectId,
      props.params.fileId
    );
    historys.value = res.data.data.historys;
    isLoading.value = false;
  } catch (error) {
    closeModal();
  }
});

/**********************************************************
 * Event Handlers
 *********************************************************/
// 모달 닫기
function closeModal() {
  emits("close", {status: false});
}
</script>

<template>
  <div class="md:w-[36rem]">
    <div class="modal-title">다운로드 내역</div>
    <button class="modal-btn-close" @click="closeModal">
      <font-awesome-icon icon="xmark" class="text-lg" />
    </button>

    <div class="modal-content">
      <div class="overflow-y-auto h-[20rem]">
        <template v-if="isLoading">
          <div class="relative w-full h-full">
            <div
              class="absolute top-0 left-0 right-0 bottom-0 w-full h-full pb-10 overflow-hidden flex flex-col items-center justify-center"
            >
              <div
                class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
              ></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="!historys.length" class="mt-gap-section text-center">
            <div class="relative w-24 h-24 m-auto rounded-full bg-gray-200">
              <img :src="Icons.IconEmptyDoc" class="postion-center w-20 h-20" />
            </div>
            <p class="mt-gap-group">다운로드 내역이 없습니다.</p>
          </div>
          <div
            v-for="(history, idx) of historys"
            :key="idx"
            class="py-gap-group border-b border-c-layout"
          >
            <div class="w-full">
              <p>{{ history.fileName }}</p>
            </div>
            <div class="flex items-center justify-between w-full mt-gap-text">
              <p class="text-md text-c-normal">{{ history.userName }}</p>
              <p class="text-md text-c-normal">
                {{ utils.String.dateFormat(utils.String.DateFormat.Created_2, history.createdAt) }}
              </p>
            </div>
          </div>
        </template>
      </div>

      <!-- <div class="modal-footer">
        <loader-button
          ref="submitButtonRef"
          class="mt-gap-group"
          :customCSS="'modal-confirm'"
          @request="handleSubmit"
        >
          확인
        </loader-button>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cs-content {
  @apply min-h-screen pt-[60px] pb-10 px-5 md:px-[10rem];
  @apply rounded-t-sm;
  @apply bg-white;
}
</style>
