import moment from "moment";

/******************************************************
 * 권한 - 한글로 변경
 *****************************************************/
export const translateRoleToKR = (role) => {
  switch (role) {
    case "owner":
      return "관리자";
    case "admin":
      return "담당자";
    case "member":
      return "팀원";
    case "extMember":
      return "제출자";
    case "unAuth":
      return "미승인";
  }
};

/******************************************************
 * 프로젝트 진행상황 - 한글로 변경
 *****************************************************/
export const translateProgressToKR = (status) => {
  switch (status) {
    case "beforeapply":
      return "미완료";
    case "inprogress":
      return "미완료";
    case "modify":
      return "수정중";
    case "completed":
      return "완료";
  }
};

/******************************************************
 * 프로젝트 진행상황 - 한글로 변경
 *****************************************************/
export const translateSubmitterProgressToKR = (status) => {
  switch (status) {
    case "beforeapply":
      return "미제출";
    case "inprogress":
      return "제출";
    case "modify":
      return "수정중";
    case "completed":
      return "제출됨";
  }
};

/******************************************************
 * 사용등급 - 대문자로 변경
 *****************************************************/
export const getGroupPlanTextUppercase = (plan) => {
  switch (plan) {
    case "free":
      return "FREE";
    case "basic":
      return "BASIC";
    case "standard":
      return "STANDARD";
    case "premium":
      return "PREMIUM";
  }
};

/******************************************************
 * 권한 - 색상 css
 *****************************************************/
export const getRoleColorClass = (role) => {
  switch (role) {
    case "owner":
      return "text-sky-500";
    case "admin":
      return "text-sky-500";
    case "member":
      return "text-teal-500";
    case "extMember":
      return "text-gray-400";
    case "unAuth":
      return "text-gray-400";
  }
};

/******************************************************
 * 팀등급 - 색상 css
 *****************************************************/
export const getGroupPlanColorClass = (plan) => {
  switch (plan) {
    case "free":
      return "c-free";
    case "basic":
      return "c-basic";
    case "standard":
      return "c-standard";
    case "premium":
      return "c-premium";
  }
};

/******************************************************
 * 바이트 단위 변환
 *****************************************************/
export const convertBytesToReadableSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // 소수점 이하 버리기: Math.floor로 소수점 이하 제거
  return Math.floor(bytes / Math.pow(k, i)) + " " + sizes[i];
};

/******************************************************
 * 한글 요일로 변경
 ******************************************************/
export const convertDayToKo = (day) => {
  const dayObj = {
    mon: "월",
    tue: "화",
    wed: "수",
    thu: "목",
    fri: "금",
    sat: "토",
    sun: "일",
  };
  return dayObj[day];
};

/******************************************************
 * 숫자 앞에 0붙이
 *****************************************************/
export const padZero = (val) => {
  let result = Number(val).toString();
  if (10 > val) {
    result = "0" + val;
  }

  return result;
};

/******************************************************
 * 날짜 YMD
 *****************************************************/
export const formatDateToYMD = (time) => {
  let t = new Number(time);
  let a = new Date(t.valueOf() * 1000);
  let year = a.getFullYear();
  let month = a.getMonth();
  let date = a.getDate();
  let hour = a.getHours();
  let min = a.getMinutes();
  let sec = a.getSeconds();
  let output = year + "." + padZero(month + 1) + "." + padZero(date);
  return output;
};

/******************************************************
 * 날짜 YMDHM
 *****************************************************/
export const formatDateToYMDHM = (time) => {
  let t = new Number(time);
  let a = new Date(t.valueOf() * 1000);
  let year = a.getFullYear();
  let month = a.getMonth();
  let date = a.getDate();
  let hour = a.getHours();
  let min = a.getMinutes();
  let sec = a.getSeconds();
  let output =
    year +
    "." +
    padZero(month + 1) +
    "." +
    padZero(date) +
    " " +
    padZero(hour) +
    ":" +
    padZero(min);
  return output;
};

/******************************************************
 * 전화번호 형식으로 변경
 ******************************************************/
export const formatPhoneNumber = (number) => {
  if (!number) {
    return "";
  }

  number = number.replace(/-/g, "");

  // 숫자를 문자열로 변환
  const numStr = number.toString();

  // 유효하지 않은 길이 처리
  // if (numStr.length < 9 || numStr.length > 11) {
  //   return number;
  // }

  let formatted = number;

  if (numStr.length === 9) {
    // 지역번호가 2자리 (예: 02-XXXX-XXXX)
    formatted = `${numStr.slice(0, 2)}-${numStr.slice(2, 5)}-${numStr.slice(5)}`;
  } else if (numStr.length === 10) {
    if (numStr.startsWith("02")) {
      // 서울 지역번호 02 (10자리: 02-XXXX-XXXX)
      formatted = `${numStr.slice(0, 2)}-${numStr.slice(2, 6)}-${numStr.slice(6)}`;
    } else {
      // 일반 지역번호 (예: 031-XXX-XXXX)
      formatted = `${numStr.slice(0, 3)}-${numStr.slice(3, 6)}-${numStr.slice(6)}`;
    }
  } else if (numStr.length === 11) {
    // 휴대전화번호 (예: 010-XXXX-XXXX)
    formatted = `${numStr.slice(0, 3)}-${numStr.slice(3, 7)}-${numStr.slice(7)}`;
  }

  return formatted;
};
// - 제거
export const formatSimplePhoneNumber = (number) => {
  if (number && number.length) {
    return number.replace(/-/g, "").trim();
  }

  return "";
};

/******************************************************
 * 카드 4자리 마다 - 넣는 정규식
 ******************************************************/
export const formatCreditCard = (value) => {
  return value.replace(/(.{4})/g, "$1-").slice(0, -1); // 마지막 '-' 제거
};

/******************************************************
 * 파일 확장자
 ******************************************************/
export const extractFileExtension = (fileName) =>
  fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();

/******************************************************
 * 날짜 형식 변환
 ******************************************************/
export const DateFormat = {
  ServerFormat: "YYYY-MM-DD HH:mm:ss",
  Created: "YYYY년 MM월 DD일 HH:mm",
  Created_2: "YYYY년 MM월 DD일 HH시 mm분",
  File: "YYYY.MM.DD HH:mm",
  MessageToday: "YYYY년 MM월 DD일",
  Deadline: "YYYY.MM.DD",
  MessageTime: "HH:mm",
};
export const dateFormat = (format, date, empty) => {
  if (!date) {
    return "-";
  }

  const e = empty && empty.length ? empty : "-";
  return date ? moment(date).format(format) : e;
};

export const onlyNumbers = (val) => {
  return val.replace(/[^0-9]/g, "");
};

export default {
  DateFormat,

  translateRoleToKR,
  translateProgressToKR,
  translateSubmitterProgressToKR,
  getGroupPlanTextUppercase,
  getRoleColorClass,
  getGroupPlanColorClass,
  convertBytesToReadableSize,
  convertDayToKo,
  padZero,
  formatDateToYMD,
  formatDateToYMDHM,
  formatPhoneNumber,
  formatSimplePhoneNumber,
  formatCreditCard,
  extractFileExtension,
  dateFormat,
  onlyNumbers,
};
