<script setup>
import {onMounted, ref} from "vue";

const images = import.meta.glob("@/assets/images/partner/*.png", {eager: true});

const getImageUrl = (name) => {
  return images[`/src/assets/images/partner/${name}.png`]?.default || "";
};

const items = ref([
  "partner1",
  "partner2",
  "partner3",
  "partner4",
  "partner5",
  "partner6",
  "partner7",
  "partner8",
]);
</script>

<!-- style="background-color: rgba(255, 255, 255, 0.9)" -->
<template>
  <div class="flex items-center w-full h-[5rem] bg-white">
    <!-- 슬라이더 영역 -->
    <div class="slider">
      <div class="slide-track">
        <!-- 원본 항목 -->
        <div class="slide" v-for="(item, index) in items" :key="index">
          <img class="" :src="getImageUrl(item)" />
        </div>
        <div class="slide" v-for="(item, index) in items" :key="index">
          <img class="" :src="getImageUrl(item)" />
        </div>
        <!-- 원본 항목 전체를 복제하여 슬라이드가 자연스럽게 이어지도록 함 -->
        <div class="slide" v-for="(item, index) in items" :key="'dup-' + index">
          <img class="" :src="getImageUrl(item)" />
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</template>

<style lang="scss" scoped>
/* 슬라이더 컨테이너: 전체 화면 너비 */
.slider {
  overflow: hidden;
  width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;
}

/* 슬라이드 트랙: 원본 + 복제본으로 총 200vw 너비 */
.slide-track {
  display: flex;
  width: 200vw; /* 원본 100vw + 복제본 100vw */
  animation: scroll 20s linear infinite;
}

/* 개별 슬라이드: 한 화면에 4개 항목 표시 */
.slide {
  flex: 0 0 calc(100vw / 8);
  text-align: center;
  box-sizing: border-box;
  margin: 0px 1rem;

  img {
    margin: auto;
    height: 25px;
  }
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 15%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 85%,
    #ffffff 100%
  );
}

@media (max-width: 768px) {
  .slide img {
    height: 16px;
  }
}

/* 애니메이션: 원본 슬라이드 영역(100vw) 만큼 왼쪽으로 이동 */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}
</style>
