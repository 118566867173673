<script setup>
import {ref, computed, onMounted, watch, onUnmounted} from "vue";
import {storeToRefs} from "pinia";
import utils from "@/common/utils";
import {Modal, ModalComponents} from "@/modules";
import {driveStore, groupStore, projectStore} from "@/stores";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
    default: null,
  },
});

const newNameRef = ref();
let tooltip = null;

onMounted(() => {
  if (props.params.file) {
    newNameRef.value.setText(props.params.file.name);
  }
});

/**********************************************************
 * 모달 닫기
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

function handleEnterChangeFileName() {
  newNameRef.value.onClickLoaderBtn();
}

async function handleChangeFileName(done) {
  const newName = newNameRef.value.getText();

  const validateName = utils.Validate.validateFileName(newName);
  if (!validateName.status) {
    tooltip = utils.Common.showTippy("#newNameInput", validateName.message, undefined, tooltip);
    done();
    return;
  }

  await driveStore().apiUpdateDriveFile({id: props.params.file.id, name: newName});
  done();
  closeModal();
}
</script>

<template>
  <div class="md:w-[32rem]">
    <div>
      <div class="modal-title">이름 변경</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content">
      <!-- 이름 ------------------------------------------------------>
      <label-input
        id="newNameInput"
        ref="newNameRef"
        class="w-full"
        type="text"
        placeholder-text="이름"
        :is-important="true"
        height="h-c-form-md"
        :isFocus="true"
        @enter="handleEnterChangeFileName"
      >
      </label-input>

      <div class="modal-footer">
        <loader-button :customCSS="'modal-confirm'" @request="handleChangeFileName">
          <slot><span class="text-white">확인</span></slot>
        </loader-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
