import axios from "@/modules/axios";

/*******************************************************************
 * 그룹 관리 API
 *******************************************************************/

/**
 * 그룹 생성
 * @param {*} name
 * @param {*} domain
 * @returns
 */
export function createGroup(name, domain) {
  return axios({
    url: `/groups`,
    method: "post",
    params: {
      noToast: 0,
    },
    data: {
      name: name,
      domain: domain,
    },
  });
}

/**
 * 그룹 리스트
 * @returns
 */
export function getGroupList(sort = "createdAt:desc", type = "all") {
  return axios({
    url: `/groups`,
    method: "get",
    params: {
      sort: sort,
      type: type,
    },
  });
}

/**
 * 최신 그룹 리스트
 * @returns
 */
export function getRecentGroup() {
  return axios({
    url: `/groups/recent`,
    method: "get",
  });
}

/**
 * 그룹 정보
 * @param {*} groupId groupDomain
 * @returns
 */
export function getGroup(groupIdDomain) {
  return axios({
    url: `/groups/${groupIdDomain}`,
    method: "get",
  });
}

/**
 * 그룹 정보 - 로그인X
 * @param {*} groupDomain
 * @returns
 */
export function getCommonGroup(groupDomain) {
  return axios({
    url: `/commons/groups/${groupDomain}`,
    method: "get",
  });
}

/*******************************************************************
 * 그룹 멤버 관리
 *******************************************************************/

/**
 * 그룹 멤버 리스트
 * @param {*} groupId
 * @param {*} isIncludeInvite 초대중인 멤버도 포함
 * @returns
 */
export function getGroupMembers(groupId, isIncludeInvite) {
  return axios({
    url: `/groups/${groupId}/members`,
    method: "get",
    params: {
      isIncludeInvite: isIncludeInvite,
    },
  });
}

/**
 * 그룹 멤버 삭제
 * @param {*} groupId
 * @param {*} deleteUserIds 멤버 아이디 리스트
 * @returns
 */
export function deleteGroupMembers(groupId, deleteUserIds) {
  return axios({
    url: `/groups/${groupId}/members/delete`,
    method: "post",
    data: {
      deleteUserIds: deleteUserIds,
    },
  });
}

/**
 * 그룹 멤버 초대
 * @param {*} groupId
 * @param {*} emails 초대할 멤버의 이메일 리스트
 * @returns
 */
export function createInviteMembers(groupId, cellPhones) {
  return axios({
    url: `/groups/${groupId}/members/invites`,
    method: "post",
    data: {
      cellPhones: cellPhones,
    },
  });
}

/**
 * 그룹 멤버 초대 삭제
 * @param {*} groupId
 * @param {*} emails 초대한 멤버의 이메일 리스트
 * @returns
 */
export function cancelInviteMembers(groupId, cellPhones) {
  return axios({
    url: `/groups/${groupId}/members/invites/delete`,
    method: "post",
    data: {
      cellPhones: cellPhones,
    },
  });
}

/*******************************************************************
 * 그룹 썸네일 관리
 *******************************************************************/

/**
 * 그룹 썸네일 업로드
 * @param {*} groupId
 * @param {*} location
 * @param {*} file
 * @returns
 */
export function uploadGroupThumbnailSignedUrl(groupId, location, file) {
  return axios({
    url: `/groups/${groupId}/thumbnail`,
    method: "post",
    data: {
      file: file,
      location: location,
    },
  });
}

/**
 * 그룹 썸네일 삭제
 * @param {*} groupId
 * @returns
 */
export function deleteGroupThumbnail(groupId) {
  return axios({
    url: `/groups/${groupId}/thumbnail`,
    method: "delete",
  });
}

/*******************************************************************
 * 그룹 정보 관리
 *******************************************************************/

export function updateGroupData(groupId, groupName, groupDomain) {
  return axios({
    url: `/groups/${groupId}`,
    method: "put",
    data: {
      name: groupName,
      domain: groupDomain,
    },
  });
}

export function getGroupAllSubmitters(groupId, keyword = null) {
  return axios({
    url: `/groups/${groupId}/submitters`,
    method: "get",
    params: {
      keyword,
    },
  });
}

export function deleteGroup(groupId, domain) {
  return axios({
    url: `/groups/${groupId}/delete`,
    method: "post",
    params: {
      noToast: 0,
    },
    data: {
      domain: domain,
    },
  });
}

/*******************************************************************
 * 주소록 관리
 *******************************************************************/

export function createAddressBook(groupId) {
  return axios({
    url: `/groups/${groupId}/address-books`,
    method: "post",
    params: {
      noToast: 0,
    },
  });
}

export function deleteAddressBook(groupId, addressGroupId) {
  return axios({
    url: `/groups/${groupId}/address-books/delete`,
    method: "post",
    data: {
      addressGroupId: addressGroupId,
    },
  });
}

export function updateAddressBook(groupId, addressGroupId, name) {
  return axios({
    url: `/groups/${groupId}/address-books/${addressGroupId}`,
    method: "put",
    data: {
      name: name,
    },
  });
}

export function getAddressBooks(groupId) {
  return axios({
    url: `/groups/${groupId}/address-books`,
    method: "get",
  });
}

export function addAddressEntrys(groupId, addressBookId, newAddresses) {
  return axios({
    url: `/groups/${groupId}/address-books/${addressBookId}/items`,
    method: "post",
    data: {
      newAddresses: newAddresses,
    },
  });
}

export function getAddressBookItems(groupId, addressBookId) {
  return axios({
    url: `/groups/${groupId}/address-books/${addressBookId}`,
    method: "get",
  });
}

export function deleteAddressBookItems(groupId, addressBookId, cellPhones) {
  return axios({
    url: `/groups/${groupId}/address-books/${addressBookId}/delete`,
    method: "post",
    data: {
      deleteAddresses: cellPhones,
    },
  });
}

/*******************************************************************
 * Default Export
 *******************************************************************/

export default {
  createGroup,
  getGroupList,
  getRecentGroup,
  getGroup,
  getCommonGroup,
  getGroupMembers,
  deleteGroupMembers,
  createInviteMembers,
  cancelInviteMembers,
  uploadGroupThumbnailSignedUrl,
  deleteGroupThumbnail,
  updateGroupData,
  getGroupAllSubmitters,
  deleteGroup,
  createAddressBook,
  deleteAddressBook,
  updateAddressBook,
  getAddressBooks,
  addAddressEntrys,
  getAddressBookItems,
  deleteAddressBookItems,
};
