/**********************************************************
 * Icons
 *********************************************************/
import IconDowple from "@/assets/icons/logo/logo-dowple-full.png";
import IconDowpleSimbol from "@/assets/icons/logo/logo-dowple-simbol.svg";
import IconDowpleSimbol2 from "@/assets/icons/logo/logo-dowple-simbol2.png";
import IconDowpleSimbol3 from "@/assets/icons/logo/logo-dowple-simbol3.png";
import IconKakao from "@/assets/icons/logo/logo-kakao.png";
import IconNaver from "@/assets/icons/logo/logo-naver.png";
import IconGoogle from "@/assets/icons/logo/logo-google.png";
import IconEmail from "@/assets/icons/email.png";
import IconSetting from "@/assets/icons/setting.png";
import IconSearch from "@/assets/icons/search.png";
import IconLink from "@/assets/icons/link.png";
import IconDragDrop from "@/assets/icons/drag-and-drop.png";
import IconCommun from "@/assets/icons/message.png";
import IconClose from "@/assets/icons/close.png";
import IconHome from "@/assets/icons/home.png";
import IconEdit from "@/assets/icons/edit.png";
import IconZip from "@/assets/icons/zip.png";
import IconCreditCard from "@/assets/icons/credit-card.png";
import IconDownload from "@/assets/icons/download.png";
import IconUpload from "@/assets/icons/upload.png";
import IconUser from "@/assets/icons/user.png";
import IconUser3 from "@/assets/icons/user (3).png";
import IconUser4 from "@/assets/icons/user (4).png";
import IconUser5 from "@/assets/icons/user (5).png";
import IconEmptyBox from "@/assets/icons/empty-box.png";
import IconClick from "@/assets/icons/click.png";
import IconUsers from "@/assets/icons/group.png";
import IconEmptyFolder from "@/assets/icons/empty-folder.png";
import IconEmptyInbox from "@/assets/icons/empty-inbox.png";
import IconEmptyDoc from "@/assets/icons/empty-documents.png";
import IconEmptyUsers from "@/assets/icons/empty-users.png";
import IconInbox from "@/assets/icons/document-upload.png";
import IconSearchFolder from "@/assets/icons/empty.png";
import IconNoChats from "@/assets/icons/no-chats.png";
import IconNoContent from "@/assets/icons/no-content.png";
import IconEmptyPage from "@/assets/icons/page.png";
import IconEmptyRule from "@/assets/icons/folder.png";

export const Icons = {
  IconDowple,
  IconDowpleSimbol,
  IconDowpleSimbol2,
  IconDowpleSimbol3,
  IconKakao,
  IconNaver,
  IconGoogle,
  IconEmail,
  IconSetting,
  IconSearch,
  IconLink,
  IconDragDrop,
  IconCommun,
  IconClose,
  IconHome,
  IconEdit,
  IconZip,
  IconCreditCard,
  IconDownload,
  IconUpload,
  IconUser,
  IconUser3,
  IconUser4,
  IconUser5,
  IconEmptyBox,
  IconClick,
  IconUsers,
  IconEmptyFolder,
  IconEmptyInbox,
  IconEmptyDoc,
  IconEmptyUsers,
  IconInbox,
  IconSearchFolder,
  IconNoChats,
  IconNoContent,
  IconEmptyPage,
  IconEmptyRule,
};

import Common from "@/common/utils-common";
import String from "@/common/utils-string";
import Cookie from "@/common/utils-cookie";
import Validate from "@/common/utils-validate";

export default {
  Icons,
  Common,
  String,
  Cookie,
  Validate,
};
