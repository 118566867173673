import {ref} from "vue";

/****************************************************
 * 체크 박스 모듈
 ****************************************************/
export const CheckModule = () => {
  const checkedList = ref([]);
  const allChecked = ref(false);

  function initializeCheckedList(length) {
    checkedList.value = Array(length).fill(false);
    allChecked.value = false;
  }

  function addItem() {
    checkedList.value.push(false);
  }

  function removeItems(indices) {
    indices.sort((a, b) => b - a);
    indices.forEach((index) => checkedList.value.splice(index, 1));
  }

  function validateAllChecked() {
    allChecked.value = checkedList.value.every((item) => item);
  }

  function toggleAllItems(state) {
    checkedList.value.fill(state);
    allChecked.value = state;
  }

  function toggleAll() {
    const newState = !allChecked.value;
    toggleAllItems(newState);
  }

  function toggleItem(index) {
    checkedList.value[index] = !checkedList.value[index];
    checkedList.value = JSON.parse(JSON.stringify(checkedList.value));
    validateAllChecked();
  }

  function getCheckedIndices() {
    return checkedList.value.reduce((indices, isChecked, index) => {
      if (isChecked) indices.push(index);
      return indices;
    }, []);
  }

  function getState(idx) {
    return checkedList.value[idx];
  }

  return {
    checkedList,
    allChecked,

    initializeCheckedList,
    addItem,
    removeItems,
    validateAllChecked,
    toggleAllItems,
    toggleAll,
    toggleItem,
    getCheckedIndices,
    getState,
  };
};

export default CheckModule;
