import {ref, computed} from "vue";
import {defineStore} from "pinia";
import axios, {AxiosError, HttpStatusCode} from "axios";
import apis from "@/apis";
import constants from "@/common/constants";
import utils from "@/common/utils.js";

export const groupStore = defineStore("group", () => {
  const groupId = ref(0);
  const groupDomain = ref("");
  const groupName = ref("");
  const groupThumbnailUrl = ref("");
  const groupPlanGrade = ref("");
  const groupMaxExtMemberCount = ref(0);
  const groupMaxStorageSize = ref(0);
  const groupCurrentStorageSize = ref(0);
  const groupMaxUploadSize = ref(0);
  const groupMember = ref(null);

  // $reset 메서드 구현
  function $reset() {
    groupId.value = 0;
    groupDomain.value = "";
    groupName.value = "";
    groupThumbnailUrl.value = "";
    groupPlanGrade.value = "";
    groupMaxExtMemberCount.value = 0;
    groupMaxStorageSize.value = 0;
    groupCurrentStorageSize.value = 0;
    groupMaxUploadSize.value = 0;
    groupMember.value = null;
  }

  /****************************************************
   * 그룹 정보 요청
   ****************************************************/
  async function apiGetGroup(gIdDomain) {
    try {
      const res = await apis.group.getGroup(gIdDomain);

      groupId.value = res.data.data.id;
      groupDomain.value = res.data.data.domain;
      groupName.value = res.data.data.name;
      groupThumbnailUrl.value = res.data.data.thumbnailUrl;
      groupPlanGrade.value = res.data.data.planGrade;
      groupMaxExtMemberCount.value = res.data.data.maxExtMemberCount;
      groupMaxStorageSize.value = res.data.data.maxStorageSize;
      groupCurrentStorageSize.value = res.data.data.currentStorageSize;
      groupMaxUploadSize.value = res.data.data.maxUploadSize;
      groupMember.value = res.data.data.myGroupMember;

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  /****************************************************
   * 그룹 썸네일 서명URL 요청
   ****************************************************/
  async function apiGetSignedUrlGroupThumbnail(file) {
    try {
      const res = await apis.group.uploadGroupThumbnailSignedUrl(groupId.value, "group", file);
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      return null;
    }
  }

  /****************************************************
   * 그룹 썸네일 삭제
   ****************************************************/
  async function apiDeleteGroupThumbnail() {
    try {
      const res = await apis.group.deleteGroupThumbnail(groupId.value);
      groupThumbnailUrl.value = null;
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      return null;
    }
  }

  /****************************************************
   * 그룹 정보 업데이트
   ****************************************************/
  async function apiUpdateGroupData(name = undefined, domain = undefined) {
    try {
      const res = await apis.group.updateGroupData(groupId.value, name, domain);
      if (name) {
        groupName.value = name;
      }
      if (domain) {
        groupDomain.value = domain;
      }

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      return null;
    }
  }

  /****************************************************
   * 그룹 제거
   ****************************************************/
  async function apiDeleteGroup(email) {
    try {
      const res = await requestDeleteGroup(groupId.value, email);
      if (HttpStatusCode.Ok === res.status) {
      }

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      return null;
    }
  }

  /****************************************************
   * 나는 그룹멤버?
   ****************************************************/
  const isGroupOwner = computed(() =>
    groupMember.value && constants.USER_ROLE_TYPE.owner === groupMember.value.role ? true : false
  );
  const isGroupMember = computed(() =>
    groupMember.value && constants.USER_ROLE_TYPE.externalMember !== groupMember.value.role
      ? true
      : false
  );

  const currentStoragePercent = computed(() => {
    const usePercent = (groupCurrentStorageSize.value / groupMaxStorageSize.value) * 100;
    return 0 < usePercent && 1 > usePercent ? 1 : usePercent.toFixed(1);
  });

  return {
    groupId,
    groupDomain,
    groupName,
    groupThumbnailUrl,
    groupPlanGrade,
    groupMaxExtMemberCount,
    groupMaxStorageSize,
    groupCurrentStorageSize,
    groupMaxUploadSize,
    groupMember,

    $reset,

    // actions ----------------------------------------
    apiGetGroup,
    apiGetSignedUrlGroupThumbnail,
    apiDeleteGroupThumbnail,
    apiUpdateGroupData,
    apiDeleteGroup,

    isGroupOwner,
    isGroupMember,
    currentStoragePercent,
  };
});
