import {ref} from "vue";
import {storeToRefs} from "pinia";
import axios, {HttpStatusCode} from "axios";
import apis from "@/apis";
import {RoutePaths} from "@/router/paths";
import constants from "@/common/constants";
import utils from "@/common/utils";
import {groupStore, projectsStore, projectStore, projectMembersStore} from "@/stores";
import {Modal, ModalComponents} from "@/modules";

/******************************************************
 * 파일 다운로드
 ******************************************************/
export const fetchDownloadSignedUrl = async (groupId, projectId, file) => {
  const res = await apis.project.downloadDriveFile(groupId, projectId, file.id);
  fileDownload(res.data.data.signedUrl, file.name, file.ext);

  return res.data.data;
};

export const fileDownload = async (url, fileName, ext = null) => {
  if (ext && ext.length) {
    fileName = fileName.slice(0, fileName.lastIndexOf(".")).toLowerCase();
    fileName = fileName + "." + ext;
  }
  try {
    // 파일 데이터 가져오기
    const response = await fetch(url);
    const blob = await response.blob();
    // Blob URL 생성
    const downloadUrl = URL.createObjectURL(blob);
    // 링크 생성 및 다운로드 설정
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName; // 원하는 파일명 설정
    // 링크 클릭하여 다운로드
    document.body.appendChild(link);
    link.click();
    // 링크 및 Blob URL 정리
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    alert("다운로드 요청중 오류가 발생했습니다");
  }
};

/******************************************************
 * 파일 업로드
 ******************************************************/
export const UploadModule = () => {
  const state = ref({
    selectedFile: null,
    uploadId: 0,
    status: constants.PROGRESS_STATUS_TYPE.inprogress,
    progressPercentage: 0,
  });

  /****************************************************
   * Request to upload project file
   ****************************************************/
  async function uploadAttachedFile(inputFile) {
    return uploadFile(inputFile, constants.FILE_UPLOAD_TYPE.attachmentSubmit, null);
  }

  async function uploadDriveSubmitFile(inputFile, submitterUserId = null) {
    return uploadFile(inputFile, constants.FILE_UPLOAD_TYPE.driveSubmit, submitterUserId, null);
  }

  async function uploadRuleSubmitFile(inputFile, ruleId) {
    return uploadFile(inputFile, constants.FILE_UPLOAD_TYPE.ruleSubmit, null, ruleId);
  }

  async function uploadFile(inputFile, uploadType, submitterUserId, ruleId) {
    state.value.selectedFile = inputFile;
    state.value.uploadId = utils.Common.generateRandomNumber(10);
    state.value.status = constants.PROGRESS_STATUS_TYPE.inprogress;
    state.value.progressPercentage = 0;

    try {
      // 1. Request signed URL from server
      const res = await fetchSignedUrl(
        {
          name: inputFile.name,
          size: inputFile.size,
        },
        uploadType,
        submitterUserId,
        ruleId
      );
      const {signedUrl, file} = res.data.data;

      // 2. Upload file to AWS S3 using the signed URL
      const xhr = await uploadToS3(signedUrl);
      if (xhr.status >= 200 && xhr.status < 300) {
        state.value.status = constants.PROGRESS_STATUS_TYPE.completed;
        state.value.progressPercentage = 100;

        // 파일 사용상태로 변경
        await updateUseState({
          id: file.id,
          useState: true,
        });

        return file;
      } else {
        state.value.status = constants.PROGRESS_STATUS_TYPE.fail;
        utils.Common.csConsolelog("Upload Fail", utils.Common.ConsolelogTypes.Error);
        throw Error("Upload Fail");
      }
    } catch (error) {
      utils.Common.csConsolelog("Error occurred during upload", utils.Common.ConsolelogTypes.Error);
      throw error;
    }
  }
  /****************************************************
   * Request signed URL for project file
   ****************************************************/
  async function fetchSignedUrl(file, uploadType, submitterUserId, ruleId) {
    try {
      const res = await apis.project.uploadDriveFile(
        groupStore().groupId,
        projectStore().projectId,
        file,
        uploadType,
        submitterUserId,
        ruleId
      );

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  /****************************************************
   * 파일 사용상태로 변경
   ****************************************************/
  async function updateUseState(file) {
    try {
      const res = await apis.project.updateDriveFile(
        groupStore().groupId,
        projectStore().projectId,
        file
      );

      projectsStore().changeProjectData(
        projectStore().projectId,
        null,
        res.data.data.project.progressStatus,
        null,
        null
      );

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  /****************************************************
   * Upload file to AWS S3
   ****************************************************/
  async function uploadToS3(signedUrl) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", signedUrl);
      // Monitor progress events
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          state.value.progressPercentage = progress;
        }
      });
      // Resolve on load
      xhr.addEventListener("load", () => resolve(xhr));
      // Resolve on error
      xhr.addEventListener("error", () => resolve(xhr));
      // Send the file
      xhr.send(state.value.selectedFile);
    });
  }

  /****************************************************
   * Delete file from AWS S3 (to be implemented)
   ****************************************************/
  async function handleFileDeletion(fileId) {
    // Placeholder for file deletion logic
  }

  return {
    state,
    uploadAttachedFile,
    uploadDriveSubmitFile,
    uploadRuleSubmitFile,
  };
};
