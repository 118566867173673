<script setup>
import {ref, computed, onMounted, watch, defineEmits} from "vue";
import {storeToRefs} from "pinia";
import {RoutePaths} from "@/router/paths";
import apis from "@/apis";
import utils from "@/common/utils";
import {groupStore} from "@/stores";
import ModuleLinkSharing from "@/views/modal/link-sharing/link-sharing";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const emits = defineEmits(["close"]);
defineExpose({fetchData, getReceivers, removeUser, clear});

const {groupId} = storeToRefs(groupStore());
const moduleLinkSharing = ref(ModuleLinkSharing());

const isLoading = ref(false);

onMounted(async () => {
  isLoading.value = true;
  await fetchData();
  isLoading.value = false;

  moduleLinkSharing.value.validateAddUserCB = validateAddUser;
  moduleLinkSharing.value.validateRemoveUserCB = validateRemoveUser;
});

async function fetchData() {
  try {
    const res = await apis.group.getAddressBooks(groupId.value);
    moduleLinkSharing.value.externalMembers = res.data.data;

    return true;
  } catch (error) {
    return false;
  }
}
function validateAddUser(receivers, addUser) {
  const isSameUser = receivers.some((receiver) => receiver.id === addUser.id);
  if (isSameUser) {
    return {
      status: false,
      message: "이미 등록되어 있습니다 ",
    };
  }

  return {
    status: true,
  };
}
function validateRemoveUser(receiver, user) {
  return receiver.id === user.id;
}

function getReceivers() {
  return moduleLinkSharing.value.receivers;
}
function removeUser(user) {
  moduleLinkSharing.value.removeUser(user);
}
function clear() {
  moduleLinkSharing.value.clear();
}

function handleGoAddress() {
  router.push({
    name: RoutePaths.TeamSettingAddress.name,
  });

  emits("close");
}
</script>

<template>
  <div>
    <div class="overflow-y-auto h-[20rem] max-h-[20rem] relative">
      <template v-if="isLoading">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden flex flex-col items-center justify-center"
        >
          <div
            class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
          ></div>
        </div>
      </template>
      <ul v-if="moduleLinkSharing.getExternalMemberCount()">
        <li
          v-for="(address, idx) in moduleLinkSharing.externalMembers"
          :key="idx"
          class="exist-user-row"
          @click="moduleLinkSharing.addUser(address)"
        >
          <font-awesome-icon icon="plus" class="mr-gap-group text-lg" />
          {{ address.name }} ({{ address.entryCount }})
        </li>
      </ul>
      <div v-else class="mt-gap-section text-center">
        <p>검색된 제출자 그룹이 없습니다.</p>
        <p class="mt-gap-group text-c-normal">
          다수의 제출자를 등록하여 그룹별로 추가할 수 있습니다.
        </p>
        <button class="mt-gap-text text-c-primary-light underline" @click="handleGoAddress">
          그룹 등록하기
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.exist-user-row {
  @apply flex relative items-center h-[3rem] p-gap-group border-b border-c-layout cursor-pointer transition-all;

  &:hover {
    @apply bg-c-selected-light;
  }
}
</style>
