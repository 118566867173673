import {ref} from "vue";
import {toast} from "vue3-toastify";

export const ModuleLinkSharing = () => {
  const receivers = [];
  const externalMembers = [];

  const validateAddUserCB = null;
  const validateRemoveUserCB = null;

  function clear() {
    this.externalMembers.value = [];
  }

  function addUser(user) {
    let validateResult = {status: true, message: ""};
    if (this.validateAddUserCB) {
      validateResult = this.validateAddUserCB(this.receivers, user);
    }

    if (validateResult.status) {
      this.receivers.push({
        id: user.id,
        name: user.name,
        cellPhone: user.cellPhone,
        entryCount: user.entryCount,
      });
    } else {
      toast.warn(validateResult.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  function removeUser(user) {
    if (this.validateRemoveUserCB) {
      const copyReceivers = [];

      for (const receiver of this.receivers) {
        if (!this.validateRemoveUserCB(receiver, user)) {
          copyReceivers.push(receiver);
        }
      }

      this.receivers = copyReceivers;
    }
  }

  function getExternalMemberCount() {
    return this.externalMembers.length;
  }

  function getReceiverCount() {
    return this.receivers.value.length;
  }

  return {
    receivers,
    externalMembers,

    validateAddUserCB,
    validateRemoveUserCB,

    clear,
    addUser,
    removeUser,
    getExternalMemberCount,
    getReceiverCount,
  };
};

export default ModuleLinkSharing;
