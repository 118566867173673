<script setup>
import {ref, computed, onMounted, watch, onUnmounted} from "vue";
import {storeToRefs} from "pinia";
import {groupStore, projectStore} from "@/stores";
import utils, {Icons} from "@/common/utils";
import {Modal, ModalComponents} from "@/modules";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";

// Reactive Variables
const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});
const groupStoreData = storeToRefs(groupStore());
const projectStoreData = storeToRefs(projectStore());

const isLoading = ref(true);
let copyLinkTooltip = null;
let deleteLinkTooltip = null;

const isDeadline = ref(false);
const deadlineDate = ref(null);
const timePickerRules = ref({
  minutes: {interval: 10},
});
const disabledDates = ref([{start: null, end: new Date()}]);

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(async () => {
  try {
    await projectStore().apiLoadProjectDetails(props.params.project.id);
  } catch (error) {
    return;
  }

  if (!projectStoreData.projectLinkUrl.value) {
    await handleCreateProjectLink();
  }

  if (projectStoreData.projectLinkDeadline.value) {
    deadlineDate.value = projectStoreData.projectLinkDeadline.value;
    isDeadline.value = true;
  }

  isLoading.value = false;

  setTimeout(() => {
    copyLinkTooltip = utils.Common.showTippy(
      "#copyLink",
      "클립보드에 복사",
      "mouseenter focus",
      copyLinkTooltip
    );
    deleteLinkTooltip = utils.Common.showTippy(
      "#deleteLink",
      "링크 삭제",
      "mouseenter focus",
      deleteLinkTooltip
    );
  }, 300);
});

onUnmounted(() => {
  copyLinkTooltip.destroy();
  deleteLinkTooltip.destroy();
});

watch(isDeadline, async () => {
  if (!isDeadline.value) {
    deadlineDate.value = "remove";
  }
});

watch(deadlineDate, async () => {
  await projectStore().apiUpdateProjectLinkDetails(deadlineDate.value, null);
});

/**********************************************************
 * Event Handlers
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

function handleCopyLink() {
  if (projectStoreData.projectLinkUrl.value) {
    utils.Common.copyToClipboard(projectStoreData.projectLinkUrl.value);
  }
}

async function handleCreateProjectLink() {
  await projectStore().apiCreateProjectLink();
}

function handelDeleteProjectLink() {
  Modal.value.openModal(
    ModalComponents.ModalCancelConfirm,
    {
      hiddenCancel: false,
      html: '<div class="text-lg">해당 링크로 신규 제출자를 등록할 수 없습니다.</div><div class="mt-gap-group text-c-dark"><i class="fi fi-sr-bullet"></i> 제출링크에서 새로운 링크로 초대 가능합니다.</div><div class="mt-gap-text text-c-dark"><i class="fi fi-sr-bullet"></i> 기존 제출자는 접속 가능합니다.</div>',
    },
    async (result) => {
      if (result.status) {
        await projectStore().apiRemoveProjectLink();
      }
    }
  );
}

function handleSendLink() {
  Modal.value.openModal(ModalComponents.ModalProjectLinkSharing, props.params);
}

// async function deleteProjectLink() {
//   await projectStore.fetchDeleteProjectLink();
// }

// function togglePassword() {
//   if (!isPassword.value) {
//     password.value = "";
//     errMsgPassword.value = "";
//   }
// }

// // Helper Methods
// async function onPasswordInputComplete() {
//   await projectStore.fetchUpdateLink(null, password.value);
// }

// function handlePasswordKeyup() {
//   if (isPassword.value) {
//     errMsgPassword.value = validateLinkPassword(password.value);
//   }
// }
</script>

<template>
  <div class="w-[39rem]">
    <div>
      <div class="modal-title">제출링크</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content min-h-[15rem]">
      <template v-if="isLoading">
        <div class="relative">
          <div
            class="absolute top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden flex flex-col items-center justify-center"
          >
            <div
              class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
            ></div>
          </div>
        </div>
      </template>

      <template v-else>
        <p class="modal-form-title">{{ props.params.project.name }} 링크</p>
        <div class="flex items-center justify-between mt-gap-group h-[3.5rem]">
          <!-- 제출링크 ------------------------------------------------------>

          <div class="w-full">
            <label-input
              type="text"
              placeholderText="제출링크"
              :initText="projectStoreData.projectLinkUrl.value"
              :disabled="true"
              :height="'h-c-form-sm'"
            >
              <template v-slot:after>
                <div class="flex m-auto p-2">
                  <button
                    id="copyLink"
                    class="cs-btn-rounded cs-btn-primary-after flex items-center justify-center"
                    @click="handleCopyLink"
                  >
                    <font-awesome-icon icon="paperclip" class="text-lg" />
                  </button>
                  <button
                    id="deleteLink"
                    class="cs-btn-rounded cs-btn-error-after flex items-center justify-center"
                    @click.stop="handelDeleteProjectLink"
                  >
                    <font-awesome-icon icon="trash" class="text-lg" />
                  </button>
                </div>
              </template>
            </label-input>
          </div>
          <div class="flex items-center h-full ml-gap-group">
            <button
              class="cs-btn cs-btn-primary w-[8rem] h-full px-c-btn bg-c-kakao-yellow flex items-center text-c-kakao"
              @click="handleSendLink"
            >
              <img class="w-8 h-8 mr-gap-item" :src="Icons.IconKakao" alt="Kakao Logo" />
              <span class="leading-8">보내기</span>
            </button>
          </div>
        </div>
        <validation-message
          :type="'desc'"
          :texts="[
            '링크를 복사하여 이메일 또는 메신저로 전송해 초대할 수 있습니다.',
            '오른쪽 보내기를 버튼을 클릭하여 제출자를 등록할 수 있습니다.',
          ]"
        ></validation-message>

        <!-- 제출기한 ------------------------------------------------------>
        <!-- <div class="mt-gap-section">
          <label class="flex items-center mt-2" for="deadline">
            <input
              id="deadline"
              class="form-check-input"
              type="checkbox"
              :disabled="projectStoreData.projectLinkId ? false : true"
              v-model="isDeadline"
              @change="1"
            />
            <span class="ml-2 h-4">기한설정</span>
          </label>
          <validation-message
            :texts="['설정된 날짜가 지나가면 접속이 차단됩니다.']"
            :type="'desc'"
          ></validation-message>
          <VDatePicker
            v-if="isDeadline"
            class="mt-3"
            v-model="deadlineDate"
            mode="dateTime"
            is24hr
            :disabled-dates="disabledDates"
            :copyRules="timePickerRules"
          />
        </div> -->
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Scoped styles here */
</style>
