import {ref, markRaw, shallowRef} from "vue";
import {csConsolelog} from "@/common/utils-common";

/**********************************************************
 * Modal Components
 **********************************************************/
import ModalCancelConfirm from "@/views/modal/modal-cancel-confirm.vue";
import utils from "@/common/utils";
import ModalProfile from "@/views/modal/modal-profile.vue";
import ModalCreateProject from "@/views/modal/modal-create-project.vue";
import ModalSettingProject from "@/views/modal/modal-setting-project.vue";
import ModalProjectLink from "@/views/modal/modal-project-link.vue";
import ModalProjectLinkSharing from "@/views/modal/modal-project-link-sharing.vue";
import ModalSettingSubmit from "@/views/modal/modal-setting-submit.vue";
import ModalChangeFilename from "@/views/modal/modal-change-filename.vue";
import ModalAttactedFiles from "@/views/modal/modal-attached-files.vue";
import ModalFeedbackWrite from "@/views/modal/modal-feedback-write.vue";
import ModalWorkplaceSearch from "@/views/modal/modal-workplace-search.vue";
import ModalChangeUserName from "@/views/modal/modal-change-username.vue";
import ModalSubmitDownload from "@/views/modal/modal-submit-download.vue";
import ModalAddAddressEntry from "@/views/modal/modal-add-address-entry.vue";
import ModalDownloadHistory from "@/views/modal/modal-download-history.vue";
import ModalInviteMember from "@/views/modal/modal-invite-member.vue";
// import ModalCreateAddressBook from "@/components/modals/modal-create-address-book.vue";
// import ModalAddAddressExist from "@/components/modals/modal-add-address-exist.vue";
// import ModalAddAddressNew from "@/components/modals/modal-add-address-new.vue";
// import ModalAddressBook from "@/components/modals/modal-address-book.vue";

export const ModalComponents = {
  ModalCancelConfirm,
  ModalProfile,
  ModalCreateProject,
  ModalSettingProject,
  ModalProjectLink,
  ModalProjectLinkSharing,
  ModalSettingSubmit,
  ModalChangeFilename,
  ModalAttactedFiles,
  ModalFeedbackWrite,
  ModalWorkplaceSearch,
  ModalChangeUserName,
  ModalSubmitDownload,
  ModalAddAddressEntry,
  ModalDownloadHistory,
  ModalInviteMember,
  //   ModalSendFeedback,
  //   ModalLinkPassword,
  //   ModalCreateAddressBook,
  //   ModalAddAddressExist,
  //   ModalAddAddressNew,
  //   ModalAddressBook,
};

function ModalModule() {
  const isVisible = ref(false);
  const activeModal = ref(null);
  const modalParams = ref({});
  let modalCallback = null;

  const modalWidth = ref(null);

  const openModal = (modalComponent, parameters = {}, callbackFn = null) => {
    if (parameters.modalWidth) {
      modalWidth.value = parameters.modalWidth;
    }

    isVisible.value = true;
    modalParams.value = parameters;
    activeModal.value = markRaw(modalComponent);
    modalCallback = callbackFn;
  };

  const onCloseModal = (result = null) => {
    isVisible.value = false;
    activeModal.value = null;
    modalWidth.value = null;
    if (modalCallback) {
      modalCallback(result);
    }
  };

  return {
    isVisible,
    activeModal,
    modalParams,
    modalWidth,

    openModal,
    onCloseModal,
  };
}

export const Modal = ref(ModalModule());
