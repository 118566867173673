import axios from "@/modules/axios";

/*******************************************************************
 * 피드백 전송
 *******************************************************************/
export function sendFeedback(groupId, projectId, text, receivers) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/feedbacks`,
    method: "post",
    data: {
      text: text,
      receivers: receivers,
    },
  });
}

/*******************************************************************
 * 피드백 리스트
 *******************************************************************/
export function getFeedbacks(groupId, projectId, receiverUserId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/feedbacks/${receiverUserId}`,
    method: "get",
  });
}

/*******************************************************************
 * 피드백 읽음
 *******************************************************************/
export function updateFeedbackReaded(groupId, projectId, senderUserId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/feedbacks/read`,
    method: "put",
    data: {
      senderUserId,
    },
  });
}

/*******************************************************************
 * 피드백 안읽음 개수
 *******************************************************************/
export function getFeedbackUnReadCount(groupId, projectId, receiverUserId) {
  return axios({
    url: `/groups/${groupId}/projects/${projectId}/feedbacks/${receiverUserId}/unread-count`,
    method: "get",
  });
}

export default {
  sendFeedback,
  getFeedbacks,
  updateFeedbackReaded,
  getFeedbackUnReadCount,
};
