import {ref} from "vue";
import {defineStore} from "pinia";
import API from "@/apis";
import {groupStore, projectsStore} from "@/stores";
import utils from "@/common/utils";
import axios, {AxiosError} from "axios";

export const projectStore = defineStore("project", () => {
  // Reactive State
  const projectId = ref(0);
  const projectName = ref("");
  const projectUseState = ref("");
  const projectProgressStatus = ref("");
  const projectIsHiddenFromClient = ref(false);
  const projectCreatedAt = ref(null);

  const projectAdmin = ref({});

  const projectRulesCount = ref(0);
  const projectExternalMembersCount = ref(0);
  const projectCompletedMembersCount = ref(0);
  const projectDownloadedFilesCount = ref(0);
  const projectNotDownloadFilesCount = ref(0);
  const projectNewNotificationCount = ref(0);

  const projectLinkId = ref("");
  const projectLinkUrl = ref("");
  const projectLinkPassword = ref("");
  const projectLinkDeadline = ref(null);

  // $reset 메서드 구현
  function $reset() {
    projectId.value = 0;
    projectName.value = "";
    projectUseState.value = "";
    projectProgressStatus.value = "";
    projectIsHiddenFromClient.value = false;
    projectCreatedAt.value = null;

    projectAdmin.value = {};

    projectRulesCount.value = 0;
    projectExternalMembersCount.value = 0;
    projectCompletedMembersCount.value = 0;
    projectDownloadedFilesCount.value = 0;
    projectNotDownloadFilesCount.value = 0;

    projectLinkId.value = "";
    projectLinkUrl.value = "";
    projectLinkPassword.value = "";
    projectLinkDeadline.value = null;
  }

  // Fetch Project Details
  async function apiLoadProjectDetails(pId) {
    try {
      const res = await API.project.getProjectDetailsById(groupStore().groupId, pId);

      projectId.value = res.data.data.id;
      projectName.value = res.data.data.name;
      projectUseState.value = res.data.data.useState;
      projectProgressStatus.value = res.data.data.progressStatus;
      projectIsHiddenFromClient.value = res.data.data.isHiddenFromClient || false;
      projectCreatedAt.value = new Date(res.data.data.createdAt);

      projectRulesCount.value = res.data.data.rulesCount;
      projectExternalMembersCount.value = res.data.data.externalMembersCount;
      projectCompletedMembersCount.value = res.data.data.completedExternalMembersCount;
      projectDownloadedFilesCount.value = res.data.data.downloadedFilesCount;
      projectNotDownloadFilesCount.value = res.data.data.unDownloadFilesCount;
      projectNewNotificationCount.value = res.data.data.newNotificationCount;

      projectAdmin.value = res.data.data.adminProjectMember || {};

      projectsStore().changeProjectData(
        projectId.value,
        projectName.value,
        projectProgressStatus.value,
        projectExternalMembersCount.value,
        projectCompletedMembersCount.value,
        projectNewNotificationCount.value
      );

      if (res.data.data.link) {
        projectLinkId.value = res.data.data.link.id;
        projectLinkUrl.value = generateProjectLinkUrl();
        projectLinkDeadline.value = res.data.data.link.deadline
          ? new Date(res.data.data.link.deadline)
          : null;
        projectLinkPassword.value = res.data.data.link.password;
      }

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  // Update Project Name
  async function apiUpdateProjectName(pId, newName) {
    try {
      const res = await API.project.updateProject(groupStore().groupId, pId, newName);
      projectsStore().modifyProjectName(pId, newName);
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  // Delete Project
  async function apiDeleteProject(pId, terminate = false) {
    try {
      const res = await API.project.deleteProject(groupStore().groupId, pId, terminate);
      projectsStore().deleteProject(pId);
      return res;
    } catch (error) {
      utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      throw error;
    }
  }

  // Create Project Link
  async function apiCreateProjectLink() {
    try {
      const res = await API.project.createProjectLink(groupStore().groupId, projectId.value);
      projectLinkId.value = res.data.data.id;
      projectLinkUrl.value = generateProjectLinkUrl();
      projectLinkDeadline.value = null;
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  /****************************************************
   * 제출함 링크 정보
   ****************************************************/
  async function apiGetProjectLink(linkId) {
    try {
      const res = await API.project.getProjectLink(linkId);
      projectLinkId.value = res.data.data.id;
      projectLinkUrl.value = generateProjectLinkUrl();
      projectLinkPassword.value = res.data.data.password;
      projectLinkDeadline.value = res.data.data.deadline ? new Date(res.data.data.deadline) : null;
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  // Delete Project Link
  async function apiRemoveProjectLink() {
    try {
      const res = await API.project.deleteProjectLink(groupStore().groupId, projectId.value);
      projectLinkId.value = "";
      projectLinkUrl.value = "";
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  // Update Project Link Details
  async function apiUpdateProjectLinkDetails(deadline, password) {
    try {
      const res = await API.project.updateProjectLink(
        groupStore().groupId,
        projectId.value,
        deadline,
        password
      );
      projectLinkDeadline.value = deadline || null;
      projectLinkPassword.value = password || "";
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  // Validate Project Link Password
  async function apiValidateLinkPassword(linkId, password) {
    try {
      const response = await API.validateLinkPassword(linkId || projectLinkId.value, password);
      return response;
    } catch (error) {
      utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      throw error;
    }
  }

  // Generate Project Link URL
  function generateProjectLinkUrl() {
    return `${window.location.origin}/links/${projectLinkId.value}?name=${encodeURIComponent(
      projectName.value.trim()
    )}`;
  }

  // Local Storage for Link State
  function saveLinkCreationState() {
    window.localStorage.setItem(`ShowCreateSubmitState_${projectId.value}`, true);
  }
  function getLinkCreationState() {
    return window.localStorage.getItem(`ShowCreateSubmitState_${projectId.value}`);
  }

  function setRuleCount(count) {
    projectRulesCount.value = count;
  }

  return {
    // Reactive State
    projectId,
    projectName,
    projectUseState,
    projectProgressStatus,
    projectIsHiddenFromClient,
    projectCreatedAt,
    projectAdmin,
    projectRulesCount,
    projectExternalMembersCount,
    projectCompletedMembersCount,
    projectDownloadedFilesCount,
    projectNotDownloadFilesCount,
    projectLinkId,
    projectLinkUrl,
    projectLinkPassword,
    projectLinkDeadline,

    $reset,

    // Methods
    apiLoadProjectDetails,
    apiUpdateProjectName,
    apiDeleteProject,
    apiCreateProjectLink,
    apiGetProjectLink,
    apiRemoveProjectLink,
    apiUpdateProjectLinkDetails,
    apiValidateLinkPassword,

    generateProjectLinkUrl,
    saveLinkCreationState,
    getLinkCreationState,
    setRuleCount,
  };
});
