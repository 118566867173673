import {ref, computed} from "vue";
import {defineStore} from "pinia";

import api from "@/apis";
import utils from "@/common/utils";
import constants from "@/common/constants";
import axios, {AxiosError} from "axios";
import {driveStore} from "./drive-store";
import {projectsStore} from "./projects-store";

export const projectMembersStore = defineStore("project-members", () => {
  const myProjectMember = ref({});
  const newProjectMembers = ref([]);
  const projectMembers = ref([]);
  const projectMemberCount = ref(0);
  const pageIndex = ref(0);
  const pageCount = ref(0);
  const currentSubmitter = ref(null);

  // $reset 메서드 구현
  function $reset() {
    myProjectMember.value = {};
    newProjectMembers.value = [];
    projectMembers.value = [];
    projectMemberCount.value = 0;
    pageIndex.value = 0;
    pageCount.value = 0;
    currentSubmitter.value = null;
  }

  async function apiJoinProject(groupId, projectId) {
    try {
      const res = await api.project.addProjectMember(groupId, projectId);
      updateMyProjectMember(res.data.data);
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiGetMyProjectMember(groupId, projectId) {
    try {
      const res = await api.project.getMyProjectMember(groupId, projectId);
      updateMyProjectMember(res.data.data);
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiGetSubmitters(
    groupId,
    projectId,
    newPageIndex = 0,
    targetSubmitterId = undefined
  ) {
    try {
      const res = await api.project.getProjectMembers(
        groupId,
        projectId,
        newPageIndex,
        targetSubmitterId
      );
      newProjectMembers.value = res.data.data.newSubmitters;
      projectMembers.value = res.data.data.submitters;
      projectMemberCount.value = res.data.data.count;
      pageIndex.value = newPageIndex;
      pageCount.value = res.data.data.pageCount;

      return res;
    } catch (error) {
      console.log(typeof error);
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiGetDetailSubmitter(groupId, projectId, submitterId) {
    try {
      const res = await api.project.getProjectMember(groupId, projectId, submitterId);
      currentSubmitter.value = res.data.data;

      return res;
    } catch (error) {
      console.log(typeof error);
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiUpdateSubmitterName(groupId, projectId, submitterId, newName) {
    try {
      const res = await api.project.updateProjectMember(groupId, projectId, submitterId, {newName});

      changeSubmitterName(submitterId, newName);

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiDeleteInviteSubmitter(groupId, projectId, cellPhone) {}

  /********************************************************
   * 나이 프로젝트 멤버 정보 변경
   ********************************************************/
  function updateMyProjectMember(data) {
    myProjectMember.value = {
      userId: data.userId,
      submitterName: data.userName,
      submitStatus: data.submitStatus,
      role: data.role,
    };
  }

  /********************************************************
   * 프로젝트 멤버 상태 변경
   ********************************************************/
  function addTotalFileLength(changeSubmitterId, length) {
    for (const submitter of projectMembers.value) {
      if (changeSubmitterId === submitter.user.id) {
        submitter.user.uploadedFileCount += length;
      }
    }
  }

  /********************************************************
   * 나이 프로젝트 멤버 정보 변경
   ********************************************************/
  function changeSubmitterName(changeSubmitterId, newName) {
    for (const submitter of projectMembers.value) {
      if (changeSubmitterId === submitter.user.id) {
        submitter.user.name = newName;
      }
    }

    if (currentSubmitter.value.user.id === changeSubmitterId) {
      currentSubmitter.value.user.name = newName;
    }
  }

  /********************************************************
   * 프로젝트 멤버 상태 변경
   ********************************************************/
  function changeSubmitStatus(changeSubmitterId, newStatus) {
    for (const submitter of projectMembers.value) {
      if (changeSubmitterId === submitter.user.id) {
        submitter.user.submitStatus = newStatus;
      }
    }

    if (currentSubmitter.value.user.id === changeSubmitterId) {
      currentSubmitter.value.user.submitStatus = newStatus;
    }
  }

  /********************************************************
   * 프로젝트 멤버 상태 변경
   ********************************************************/
  function changeNewNotification(changeSubmitterId, newNotiCount = 0) {
    for (const submitter of projectMembers.value) {
      if (changeSubmitterId === submitter.user.id) {
        submitter.user.newNotificationCount = newNotiCount;
      }
    }
  }

  /********************************************************
   * 프로젝트 멤버 피드 읽음상태 변경
   ********************************************************/
  function changeUnReadFeedCount(changeUserId) {
    for (const submitter of projectMembers.value) {
      if (changeUserId === submitter.user.id) {
        submitter.user.unReadFeedCount = 0;
      }
    }
  }

  const adminRoles = new Set([
    constants.USER_ROLE_TYPE.owner,
    constants.USER_ROLE_TYPE.administrator,
    constants.USER_ROLE_TYPE.member,
  ]);
  const submitterRoles = new Set([constants.USER_ROLE_TYPE.externalMember]);

  const isRoleAdmin = computed(() => adminRoles.has(myProjectMember.value.role));
  const isRoleSubmitter = computed(() => submitterRoles.has(myProjectMember.value.role));
  const totalSubmitterCount = computed(
    () => projectMembers.value.length + newProjectMembers.value.length
  );

  return {
    myProjectMember,
    newProjectMembers,
    projectMembers,
    projectMemberCount,
    pageIndex,
    pageCount,
    currentSubmitter,

    $reset,

    apiJoinProject,
    apiGetMyProjectMember,
    apiGetSubmitters,
    apiGetDetailSubmitter,
    apiUpdateSubmitterName,
    apiDeleteInviteSubmitter,

    addTotalFileLength,
    changeSubmitterName,
    changeSubmitStatus,
    changeNewNotification,
    changeUnReadFeedCount,

    isRoleAdmin,
    isRoleSubmitter,
    totalSubmitterCount,
  };
});
