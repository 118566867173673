<script setup>
import {ref, computed, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import utils, {Icons} from "@/common/utils";
import {CheckModule} from "@/modules";
import {
  userStore,
  groupStore,
  projectStore,
  projectMembersStore,
  feedbackStore,
  driveStore,
} from "@/stores";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import Dropmenu from "@/components/dropmenu.vue";
import DropmenuUser from "@/components/dropmenu-user.vue";
import Thumbnail from "@/components/thumbnail.vue";
import UserContainer from "@/components/user-container.vue";

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
    default: [],
  },
});

const invites = ref([]);

onMounted(async () => {
  invites.value = props.params.invites;
});

/**********************************************************
 * 모달 닫기
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

function generateDomainUrl(groupDomain) {
  return groupDomain + "." + import.meta.env.VITE_APP_BASE_WEB_URL_PATH;
}

async function handleRefuse(done, invite, arrayIdx) {
  try {
    const res = await apis.account.refuseMyGroupInvite(invite.id);
    invites.value.splice(arrayIdx, 1);
  } catch (error) {
    throw error;
  } finally {
    done();
  }
}

async function handleConfirm(done, invite, arrayIdx) {
  try {
    const res = await apis.account.confirmMyGroupInvite(invite.id);
    invites.value.splice(arrayIdx, 1);
  } catch (error) {
    throw error;
  } finally {
    done();
  }
}
</script>

<template>
  <div class="w-[35rem]">
    <div>
      <div class="modal-title">팀 초대요청 리스트</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content">
      <div>{{ invites.length }}개의 팀 초대요청이 있습니다, 팀의 멤버로 등록되어 사용 합니다.</div>

      <div class="mt-gap-group h-[20rem]">
        <div
          v-for="(invite, idx) in invites"
          :key="idx"
          class="flex items-center justify-between p-gap-group border-b border-c-layout"
        >
          <div class="flex items-center">
            <thumbnail
              class="border border-c-layout"
              :imageUrl="invite.group.thumbnailUrl"
              :defaultIcon="false"
              :size="'3.2rem'"
              :round="'3px'"
              :backgroundColor="'var(--theme-color-light-thumbnail-background)'"
            >
              <slot>
                <div class="w-10 h-10">
                  <img :src="Icons.IconDowpleSimbol" />
                </div>
              </slot>
            </thumbnail>

            <div class="w-full ml-gap-group">
              <div class="flex items-center">
                <span class="font-semibold text-lg">{{ invite.group.name }}</span>
              </div>
              <p class="mt-gap-text text-md text-c-light">
                {{ generateDomainUrl(invite.group.domain) }}
              </p>
            </div>
          </div>
          <div class="">
            <loader-button
              ref="refuseBtnRef"
              :customCSS="'cs-btn cs-btn-error-after mr-gap-item p-c-btn-sm border border-c-layout text-md'"
              @request="
                (done) => {
                  handleRefuse(done, invite, idx);
                }
              "
            >
              거절
            </loader-button>
            <loader-button
              ref="refuseBtnRef"
              :customCSS="'cs-btn cs-btn-error-after mr-gap-item p-c-btn-sm border border-c-layout text-md'"
              @request="
                (done) => {
                  handleConfirm(done, invite, idx);
                }
              "
            >
              승인
            </loader-button>
            <!-- <button
              class="cs-btn cs-btn-error-after mr-gap-item p-c-btn-sm border border-c-layout text-md"
              @click="handleRefuse(invite)"
            >
              거절
            </button> -->
            <!-- <button
              class="cs-btn cs-btn-primary-after p-c-btn-sm border border-c-layout text-md"
              @click="handleConfirm(invite)"
            >
              승인
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
