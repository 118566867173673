// src/socketService.js
import {io} from "socket.io-client";

class CsSocket {
  constructor() {
    this.socket = null;
  }

  // 소켓 서버에 연결
  connect() {
    if (!this.socket) {
      this.socket = io(import.meta.env.VITE_APP_SOCKET_URL, {
        transports: ["websocket"],
      });

      this.socket.on("connect", () => {
        console.log("소켓 서버에 연결됨");
      });

      this.socket.on("disconnect", () => {
        console.log("소켓 서버 연결 끊김");
      });
    }
  }

  // 특정 프로젝트 방에 접속
  join(userId) {
    if (this.socket) {
      this.socket.emit("join", {userId});
      console.log(`소켓 접속: ${userId}`);
    }
  }

  // 특정 프로젝트 방에 접속
  joinGroupRoom(userId, groupId) {
    if (this.socket) {
      this.socket.emit("join", {userId, groupId});
      console.log(`팀 방에 접속: ${groupId}`);
    }
  }

  // 특정 프로젝트 방에 접속
  joinProjectRoom(userId, projectId) {
    if (this.socket) {
      this.socket.emit("join", {userId, projectId});
      console.log(`프로젝트 방에 접속: ${projectId}`);
    }
  }

  // 특정 이벤트를 프로젝트 방에 전송
  emitEvent(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  // 서버로부터 특정 이벤트를 받을 때 처리
  onEvent(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  // 서버 연결 해제
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }
}

export default new CsSocket();
