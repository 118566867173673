<script setup>
import {ref, computed, onMounted, watch, onUnmounted} from "vue";
import {storeToRefs} from "pinia";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import {RoutePaths} from "@/router/paths";
import utils from "@/common/utils";
import constants from "@/common/constants";
import {Modal, ModalComponents, DragDropModule, UploadModule} from "@/modules";
import {groupStore, projectStore, submitStore, driveStore} from "@/stores";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
// import SubmitFile from "@/views/modal/modal-attached-file.vue";

const emits = defineEmits(["changeRow", "deleteRow"]);
const props = defineProps({
  rule: {
    type: Object,
    required: true,
  },
  extended: {
    type: Boolean,
    default: false,
  },
});
const dragDrop = ref(DragDropModule());

const fileInputRef = ref();
const titleInputRef = ref();
const changeTitleButtonRef = ref();
const files = ref([]);
const isExtend = ref(false);
let tooltip = null;

onMounted(() => {
  refreshData();

  isExtend.value = props.extended;

  dragDrop.value.setTargetSelector("dragdrop-wrapper");
  dragDrop.value.setDropCallback(handleCbUploadFile);
});

watch(
  () => props.rule,
  () => {
    refreshData();
  }
);

function refreshData() {
  files.value = props.rule.files.map((val) => {
    return {
      file: val,
      inputFile: null,
    };
  });
}

async function handleUpdate() {
  changeTitleButtonRef.value.onClickLoaderBtn();
}

async function handleChangeTitle(done) {
  let title = titleInputRef.value.getText();
  title = title.trim();

  if (0 === title.length) {
    done();
    return;
  }

  if (2 > title.length || 64 < title.length) {
    tooltip = utils.Common.showTippy(
      "#submitTitleInput",
      "2자 이상 64자 이하만 허용합니다",
      undefined,
      tooltip
    );
    done();
    return;
  }

  const newRuleData = JSON.parse(JSON.stringify(props.rule));
  newRuleData.title = title;

  await submitStore().apiUpdateRules([newRuleData]);
  done();

  props.rule.title = title;

  reloadWorkplace();
}

function handleToggleExtend() {
  isExtend.value = !isExtend.value;
}
function handleDeleteRule() {
  emits("deleteRow", props.rule);
}

/**********************************************************
 * 공통자료 리스트 삭제
 *********************************************************/
function handleRowIndex(changeType) {
  emits("changeRow", props.rule, changeType);
}
async function handleDeleteAttachedFile(file) {
  if (!file.id || 0 === file.id) {
    deleteFileRow(file.id);
    return;
  }

  await driveStore().apiDeleteDriveFiles([
    {
      id: file.id,
      key: file.key,
    },
  ]);
  await submitStore().apiUpdateRules([
    {
      ruleId: props.rule.ruleId,
      attachedFileIds: files.value.map((attachedFile) => {
        return attachedFile.file.id;
      }),
    },
  ]);

  deleteFileRow(file.id);
  submitStore().deletedAttachedFile(file.id);
  reloadWorkplace();
}
function deleteFileRow(fileId) {
  let rowIdx = 0;
  for (const file of files.value) {
    if (fileId === file.file.id) {
      break;
    }
    rowIdx++;
  }

  files.value.splice(rowIdx, 1);
}

/**********************************************************
 * 파일 업로드
 **********************************************************/
function handleUploadFile(event) {
  fileInputRef.value.click();
}
function handleCbUploadFile(files) {
  fileUpload(files);
}
function handleChangeInputFile(event) {
  const inputFiles = event.target.files;
  fileUpload(inputFiles);
}
function fileUpload(inputFiles) {
  for (let i = 0; i < inputFiles.length; i++) {
    if (constants.UPLOAD_SIZE_LIMIT.basic <= inputFiles[i].size) {
      toast.warn(
        utils.String.convertBytesToReadableSize(constants.UPLOAD_SIZE_LIMIT.basic) +
          " 이하 파일만 업로드 가능합니다",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }
  }

  for (let i = 0; i < inputFiles.length; i++) {
    const inputFile = inputFiles[i];
    files.value.push({
      file: {
        id: 0,
        name: inputFile.name,
        size: inputFile.size,
      },
      inputFile: inputFile,
    });
  }
}
async function handleUploadedAttachedFile(file) {
  props.rule.files.push(file);
  if (!props.rule.attachedFileIds) {
    props.rule["attachedFileIds"] = [];
  }

  props.rule.attachedFileIds.push(file.id);

  await submitStore().apiUpdateRules([props.rule]);
  reloadWorkplace();
}

async function reloadWorkplace() {
  if (RoutePaths.WorkPlace.name === route.name) {
    await driveStore().apiGetSubmitter(route.params.submitterId);
  }
}
</script>

<template>
  <div
    class="overflow-hidden mb-gap-group border-2 border-dashed border-c-first-dark transition-all"
  >
    <!-- <input
      name="upload"
      type="file"
      multiple
      ref="fileInputRef"
      charset="utf-8"
      v-show="false"
      @change="handleChangeInputFile"
    /> -->

    <div
      class="flex items-center justify-between px-gap-group py-gap-group"
      @click="handleToggleExtend"
    >
      <div class="w-full">
        <p>{{ props.rule.title }}</p>
        <p class="mt-gap-text text-c-light text-sm">클릭 또는 드래그&드롭 파일 업로드</p>
      </div>
      <div class="flex items-center">
        <div class="w-[5rem] text-c-normal">미제출</div>
        <div class="min-w-[2rem]">
          <!-- <button
            class="cs-btn cs-btn-rounded cs-btn-primary-after"
            @click.stop="handleRowIndex('up')"
          >
            <i class="fi fi-sr-caret-up text-lg"></i>
          </button>
          <button
            class="cs-btn cs-btn-rounded cs-btn-primary-after"
            @click.stop="handleRowIndex('down')"
          >
            <i class="fi fi-sr-caret-down text-lg"></i>
          </button> -->
          <button
            class="cs-btn cs-btn-rounded cs-btn-error-after flex"
            @click.stop="handleDeleteRule"
          >
            <font-awesome-icon icon="xmark" class="text-lg" />
          </button>
        </div>
      </div>
    </div>

    <!-- <transition name="slideDown"> -->
    <!-- <template>
      <div class="slide-down" :class="[{show: isExtend}]">
        <div class="p-gap-group border-t border-c-layout">
          <div class="">
            <label-input
              id="submitTitleInput"
              ref="titleInputRef"
              class="w-full"
              height="h-c-form-sm"
              type="text"
              placeholder-text="제출자료 내용"
              :is-important="true"
              :init-text="props.rule.title"
              @enter="handleUpdate"
            >
              <template v-slot:after>
                <div class="p-gap-item">
                  <loader-button
                    ref="changeTitleButtonRef"
                    class="cs-btn cs-btn-line p-gap-group text-md"
                    @request="handleChangeTitle"
                    >변경</loader-button
                  >
                </div>
              </template>
            </label-input>
          </div>

          <div
            class="file-upload h-[3.5rem] mt-gap-group p-gap-group border border-dashed rounded-md cursor-pointer hover:border-c-primary-dark transition-all"
            :class="
              dragDrop.isDragging
                ? 'bg-sky-100 border-c-primary-dark'
                : 'bg-blue-50 border-gray-300'
            "
            @dragenter="dragDrop.onDragEnter"
            @dragover="dragDrop.onDragOver"
            @dragleave="dragDrop.onDragLeave"
            @drop="dragDrop.onDrop($event, props.rule)"
            @click.stop="handleUploadFile"
          >
            <p class="text-sm leading-7">클릭 또는 파일을 드래그 앤 드롭</p>
          </div>
          <validation-message
            type="desc"
            :texts="['공통자료이 필요한 경우 업로드해주세요.']"
          ></validation-message>

          <div class="overflow-y-auto mt-gap-group rounded-md">
            <submit-file
              v-for="(item, idx) in files"
              :key="idx"
              :file="item.file"
              :inputFile="item.inputFile"
              @uploaded="handleUploadedAttachedFile"
              @delete="handleDeleteAttachedFile"
            ></submit-file>
          </div>
        </div>
      </div>
    </template> -->
    <!-- </transition> -->
  </div>
</template>

<style lang="scss" scoped>
.slide-down {
  max-height: 0;
  overflow: hidden; /* 내용이 튀어나오지 않도록 */
  transition: max-height 0.5s;
}

.slide-down.show {
  max-height: 1000px; /* 실제보다 넉넉하게 지정 */
}
</style>
