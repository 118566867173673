import {ref, computed} from "vue";
import {defineStore} from "pinia";
import axios, {Axios, HttpStatusCode} from "axios";
import {toast} from "vue3-toastify";

import apis from "@/apis";
import utils from "@/common/utils";
import {commonStore, userStore, groupStore, projectStore, projectMembersStore} from "@/stores";

export const feedbackStore = defineStore("feedback", () => {
  const feedMessages = ref([]);

  async function apiSendFeedback(text, receivers) {
    try {
      const res = await apis.feedback.sendFeedback(
        groupStore().groupId,
        projectStore().projectId,
        text,
        receivers
      );

      const message = res.data.data;
      message.senderUserId = userStore().userId;

      feedMessages.value.push(res.data.data);

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiGetFeedbacks(receiverUserId) {
    try {
      const res = await apis.feedback.getFeedbacks(
        groupStore().groupId,
        projectStore().projectId,
        receiverUserId
      );
      feedMessages.value = res.data.data;
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiReaded(senderUserId) {
    try {
      const res = await apis.feedback.updateFeedbackReaded(
        groupStore().groupId,
        projectStore().projectId,
        senderUserId
      );
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiGetUnReadCount() {
    try {
      const res = await apis.feedback.getFeedbackUnReadCount();
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  return {
    feedMessages,

    apiSendFeedback,
    apiGetFeedbacks,
    apiReaded,
    apiGetUnReadCount,
  };
});
