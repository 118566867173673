export const RoutePaths = {
  Landing: {path: "/landing", name: "landing"},
  Main: {path: "/", name: "main"},
  Signin: {
    path: "signin",
    name: "signin",
  },
  SigninKakao: {
    path: "signin/kakao",
    name: "signin-kakao",
  },
  SigninNaver: {
    path: "signin/naver",
    name: "signin-naver",
  },
  SigninGoogle: {
    path: "signin/google",
    name: "signin-google",
  },
  SigninEmail: {
    path: "signin/email",
    name: "signin-email",
  },
  SignupEmail: {
    path: "signup/email",
    name: "signup-email",
  },
  SignupVerifyEmailCode: {
    path: "signup/verify-code",
    name: "signup-verify-code",
  },
  FindPassword: {
    path: "find-password",
    name: "find-password",
  },
  UpdatePassword: {
    path: "update-password",
    name: "update-password",
  },
  User: {
    path: "/user",
    name: "user",
  },
  CellPhone: {
    path: "/user/cellphone",
    name: "cellphone",
  },
  Teams: {
    path: "/teams",
    name: "teams",
  },
  CreateTeam: {
    path: "/teams/create",
    name: "create-team",
  },
  Plan: {
    path: "/teams/plan",
    name: "plan",
  },
  Team: {
    path: "/team",
    name: "team",
  },
  TeamSetting: {
    path: "/team/setting",
    name: "team-setting",
  },
  TeamSettingDefault: {
    path: "/team/setting/default",
    name: "team-setting-default",
  },
  TeamSettingMember: {
    path: "/team/setting/member",
    name: "team-setting-member",
  },
  TeamSettingPayment: {
    path: "/team/setting/payment",
    name: "team-setting-payment",
  },
  TeamSettingAddress: {
    path: "/team/setting/address",
    name: "team-setting-address",
  },
  TeamProjects: {
    path: "/team/projects",
    name: "team-projects",
  },
  Project: {
    path: "/team/projects/:projectId",
    name: "project-detail",
  },
  WorkPlace: {
    path: "/team/projects/:projectId/submitters/:submitterId",
    name: "work-place",
  },
  ProjectLink: {
    path: "/links/:linkId",
    name: "project-link",
  },
  // PaymentSetting: {
  //   path: "/my/team/:groupId/payment-setting",
  //   name: "payment-setting",
  // },
  // PaymentInfoSetting: {
  //   path: "/my/team/:groupId/payment-setting/info",
  //   name: "payment-info-setting",
  // },
  // PaymentUpgradeSetting: {
  //   path: "/my/team/:groupId/payment-setting/upgrade",
  //   name: "payment-upgrade-setting",
  // },
  // PaymentPaySetting: {
  //   path: "/my/team/:groupId/payment-setting/pay",
  //   name: "payment-pay-setting",
  // },
  // MemberSetting: {
  //   path: "/my/team/:groupId/member-setting",
  //   name: "member-setting",
  // },
  // AddressBookSetting: {
  //   path: "/my/team/:groupId/address-setting",
  //   name: "address-book-setting",
  // },
  // ProjectSubmits: {
  //   path: "/projects/:projectId/submits",
  //   name: "project-submits",
  // },
  // ProjectSubmit: {
  //   path: "/projects/:projectId/submit",
  //   name: "project-submit",
  // },
  // ProjectDrive: {
  //   path: "/projects/:projectId/drive",
  //   name: "project-drive",
  // },
  // ProjectDriveSearch: {
  //   path: "/projects/:projectId/drive/search",
  //   name: "project-drive-search",
  // },
  // Feedbacks: {
  //   path: "/my/feedback",
  //   name: "feedback",
  // },
  // FeedbackRoom: {
  //   path: "/my/feedback/:receiverUserId",
  //   name: "feedback-room",
  // },
};
