<script setup>
import {ref, onMounted} from "vue";
import {storeToRefs} from "pinia";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import {RoutePaths} from "@/router/paths";
import utils, {Icons} from "@/common/utils";
import {groupStore, projectStore, projectMembersStore} from "@/stores";
import project from "@/apis/project";

const {groupId, groupName} = storeToRefs(groupStore());
const {projectId, projectName, projectRulesCount} = storeToRefs(projectStore());

const emits = defineEmits(["onGoSubmitter"]);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

function handleGo() {
  emits("onGoSubmitter", {projectId: project.id, submitterId: props.item.id});
}
</script>

<template>
  <div
    class="flex items-center w-full row-height row-hover px-gap-group border-b border-c-layout cursor-pointer"
  >
    <div class="flex items-center w-full" style="min-width: 0">
      <div class="mr-gap-group">
        <span
          class="cs-badge cs-badge-sm min-w-[3rem]"
          :class="'cs-badge2-' + props.item.submitStatus"
        >
          {{ utils.String.translateSubmitterProgressToKR(props.item.submitStatus) }}
        </span>
      </div>

      <div class="flex items-center relative w-full" style="min-width: 0">
        <div class="flex items-center w-full">
          <p class="w-full cs-ellipsis1">
            {{ props.item.name }}
          </p>
          <template v-if="props.item.liveStatus">
            <p class="min-w-[9rem] text-md text-c-normal">
              {{ utils.String.formatPhoneNumber(props.item.cellPhone) }}
            </p>
          </template>
          <template v-else>
            <p class="min-w-[9rem] text-md text-c-error">(탈퇴 계정)</p>
          </template>
          <button class="cs-btn cs-btn-rounded cs-btn-primary-after" @click="handleGo">
            <font-awesome-icon icon="angle-right" class="" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
