import {ref, computed} from "vue";
import {defineStore} from "pinia";
import apis from "@/apis";
import utils from "@/common/utils.js";
import constants from "@/common/constants";
import axios, {AxiosError, HttpStatusCode} from "axios";

export const userStore = defineStore("user", () => {
  const userId = ref(0);
  const userEmail = ref();
  const userName = ref();
  const userRole = ref();
  const userThumbnailUrl = ref();
  const userCellPhone = ref();
  const userIsAlarm = ref();
  const userIsEmailAlarm = ref();
  const userInActiveDays = ref();
  const userInActiveStartTime = ref();
  const userInActiveEndTime = ref();
  const userSnsProvider = ref();

  /****************************************************
   * Fetch user data
   ****************************************************/
  async function apiLoadUserData() {
    if (userId.value) {
      if (!userCellPhone.value || !userCellPhone.value.length) {
        return {status: HttpStatusCode.Forbidden};
      }

      return {status: HttpStatusCode.Ok};
    }

    try {
      const res = await apis.account.getUserData();

      // Update user data
      const user = res.data.data;
      userId.value = user.id;
      userEmail.value = user.email;
      userName.value = user.name;
      userRole.value = user.role;
      userThumbnailUrl.value = user.thumbnailUrl;
      userCellPhone.value = user.cellPhone;
      userIsAlarm.value = user.isAlarm;
      userIsEmailAlarm.value = user.isEmailAlarm;
      userInActiveDays.value = user.inActiveDays;
      userInActiveStartTime.value = user.inActiveStartTime;
      userInActiveEndTime.value = user.inActiveEndTime;
      userSnsProvider.value = user.snsProvider;

      // If phone number is missing, return Forbidden status
      if (!userCellPhone.value || !userCellPhone.value.length) {
        res.status = HttpStatusCode.Forbidden;
      }

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }

      throw error;
    }
  }

  /****************************************************
   * Update user data
   ****************************************************/
  async function apiUpdateUserData(values) {
    try {
      const res = await apis.account.updateUserData(values);
      if (values.name) {
        userName.value = values.name.trim();
      }
      if (values.cellPhone !== undefined) {
        userCellPhone.value = values.cellPhone.trim() || "";
      }
      if (values.isAlarm !== undefined) {
        userIsAlarm.value = values.isAlarm;
      }
      if (values.isEmailAlarm !== undefined) {
        userIsEmailAlarm.value = values.isEmailAlarm;
      }
      if (values.inActiveDays !== undefined) {
        userInActiveDays.value = values.inActiveDays;
      }
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  /****************************************************
   * Get signed URL for user thumbnail
   ****************************************************/
  async function getSignedUrlForThumbnail() {
    try {
      const res = await requestSignedUrlUserThumbnail();
      return res;
    } catch (error) {
      utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      return null;
    }
  }

  /****************************************************
   * Delete user thumbnail
   ****************************************************/
  async function removeUserThumbnail(file) {
    try {
      const res = await requestDeleteUserThumbnail();
      if (res.status !== HttpStatusCode.Ok) {
        return res;
      }

      userThumbnailUrl.value = null;
      return res;
    } catch (error) {
      utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      return null;
    }
  }

  /****************************************************
   * User withdrawal
   ****************************************************/
  async function apiDeleteUserAccount(email) {
    try {
      const res = await apis.account.withdrawUserAccount(email);
      if (HttpStatusCode.Ok === res.status) {
        logout();
      }

      return res;
    } catch (error) {
      utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      return error;
    }
  }

  /****************************************************
   * Log out
   ****************************************************/
  function logout() {
    utils.Cookie.removeCookie(constants.COOKIE_KEY.token);
    utils.Cookie.removeCookie(constants.COOKIE_KEY.redirect);
    window.location.href = utils.Common.getBaseWebUrl();
  }

  return {
    userId,
    userEmail,
    userName,
    userRole,
    userThumbnailUrl,
    userCellPhone,
    userIsAlarm,
    userIsEmailAlarm,
    userInActiveDays,
    userInActiveStartTime,
    userInActiveEndTime,
    userSnsProvider,
    // fetch ----------------------------------------
    apiLoadUserData,
    apiUpdateUserData,
    getSignedUrlForThumbnail,
    removeUserThumbnail,
    apiDeleteUserAccount,
    // action ----------------------------------------
    logout,
  };
});
