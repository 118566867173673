import {ref} from "vue";

/**********************************************************
 * File Drag & Drop Module
 *********************************************************/
export function DragDropModule() {
  const isDragging = ref(false);
  let targetSelector = null;
  let dropCallback = null;

  // 드래그 이벤트 카운터
  let dragCounter = 0;

  function dragEnter(e) {
    e.preventDefault();
    dragCounter++;
    // overlay.style.display = "flex";
    isDragging.value = true;
  }

  function dragOver(e) {
    e.preventDefault();
  }

  function dragLeave(e) {
    e.preventDefault();
    dragCounter--;
    // 모든 드래그 이벤트가 종료되었을 때만 숨김 처리
    if (dragCounter === 0) {
      // overlay.style.display = "none";
      isDragging.value = false;
    }
  }

  function drop(e) {
    e.preventDefault();
    // overlay.style.display = "none";
    isDragging.value = false;
    dragCounter = 0;
    const files = e.dataTransfer.files;
    if (dropCallback) {
      dropCallback(files);
    }
  }

  function onDragEnter(event) {
    event.preventDefault();
    if (targetSelector) {
      isDragging.value = event.target.closest(targetSelector) ? true : false;
    } else {
      isDragging.value = true;
    }

    console.log("onDragEnter: " + isDragging.value);
  }

  function onDragLeave(event) {
    event.preventDefault();
    isDragging.value = false;
    console.log("onDragLeave: " + isDragging.value);
  }

  function onDragOver(event) {
    event.preventDefault();
    if (targetSelector) {
      isDragging.value = event.target.closest(targetSelector) ? false : true;
    } else {
      isDragging.value = true;
    }
    console.log("onDragOver: " + isDragging.value);
  }

  function onDrop(event) {
    event.preventDefault();
    isDragging.value = false;
    if (dropCallback) {
      dropCallback(event.dataTransfer.files);
    }
  }

  return {
    isDragging,
    setTargetSelector: (selector) => (targetSelector = selector),
    setDropCallback: (callback) => (dropCallback = callback),

    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,

    dragEnter,
    dragLeave,
    dragOver,
    drop,
  };
}
