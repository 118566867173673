import {ref, computed} from "vue";
import {defineStore} from "pinia";
import axios, {HttpStatusCode} from "axios";
import apis from "@/apis";
import utils from "@/common/utils";
import {toast} from "vue3-toastify";
import {
  commonStore,
  userStore,
  groupStore,
  projectStore,
  projectMembersStore,
  projectsStore,
} from "@/stores";

export const driveStore = defineStore("drive", () => {
  const submitterRules = ref([]);
  const submitterFiles = ref([]);
  const activeTargetFeedId = ref(null);
  const activeTargetFiles = ref([]);
  const attachedFiles = ref([]);

  async function apiGetAttachedFiles() {
    try {
      const res = await apis.project.getAttachedFiles(
        groupStore().groupId,
        projectStore().projectId
      );
      attachedFiles.value = res.data.data.attachedFiles;

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiGetSubmitter(userId) {
    try {
      const res = await apis.project.getSubmitter(
        groupStore().groupId,
        projectStore().projectId,
        userId
      );

      submitterRules.value = res.data.data.rules;
      submitterFiles.value = res.data.data.files;

      sortRules();

      for (const rule of submitterRules.value) {
        for (const file of submitterFiles.value) {
          if (file.ruleId && file.ruleId === rule.ruleId) {
            rule["submitFile"] = file;
          }
        }
      }

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiUpdateDriveFile(file) {
    try {
      const res = await apis.project.updateDriveFile(
        groupStore().groupId,
        projectStore().projectId,
        file
      );

      changeFileNmae(file);

      await projectMembersStore().apiGetSubmitters(
        groupStore().groupId,
        projectStore().projectId,
        projectMembersStore().pageIndex
      );

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiDeleteDriveFiles(files) {
    if (!Array.isArray(files)) {
      console.error("Delete File is Array");
      return;
    }

    try {
      const realUploadFiles = files.filter((file) => {
        return file.id && 3 < file.id.length;
      });

      const res = await apis.project.deleteDriveFiles(
        groupStore().groupId,
        projectStore().projectId,
        realUploadFiles
      );

      for (const file of files) {
        deleteDriveFile(file);
      }

      await projectMembersStore().apiGetSubmitters(
        groupStore().groupId,
        projectStore().projectId,
        projectMembersStore().pageIndex
      );

      projectsStore().changeProjectData(
        projectStore().projectId,
        null,
        res.data.data.project.progressStatus,
        null,
        null
      );

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  function addDriveFile(inputFile, submitter, ruleId = undefined) {
    if (isSameFileName(inputFile.name)) {
      toast.warn("이름이 같은 파일이 있습니다", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const file = {
      inputFile: inputFile,
      id: null,
      name: inputFile.name.normalize(),
      userId: submitter.userId,
      userName: submitter.userName,
      creatorUserId: userStore().userId,
      creatorUserName: userStore().userName,
      size: inputFile.size,
      ext: utils.String.extractFileExtension(inputFile.name),
      createdAt: null,
    };

    if (ruleId) {
      file["rule"] = {ruleId: ruleId};

      for (const rule of submitterRules.value) {
        if (rule.ruleId === file.rule.ruleId) {
          rule["submitFile"] = file;
        }
      }
    }

    submitterFiles.value.push(file);
  }

  function deleteDriveFile(deleteFile) {
    submitterFiles.value = submitterFiles.value.filter((file) => {
      return deleteFile.id !== file.id;
    });

    for (const rule of submitterRules.value) {
      if (rule.submitFile) {
        if (rule.submitFile.id === deleteFile.id) {
          rule.submitFile = null;
        }
      }
    }
  }

  function isSameFileName(fileName) {
    let isSameFile = false;

    for (const file of submitterFiles.value) {
      if (fileName.normalize() === file.name.normalize()) {
        isSameFile = true;
      }
    }

    return isSameFile;
  }

  function changeFileNmae(changedFile) {
    for (const file of submitterFiles.value) {
      if (file.id === changedFile.id) {
        if (changedFile.name) {
          file.name = changedFile.name;
          return;
        }
      }
    }

    for (const file of attachedFiles.value) {
      if (file.id === changedFile.id) {
        if (changedFile.name) {
          file.name = changedFile.name;
          return;
        }
      }
    }
  }

  function setActiveTarget(feedId = null, targets = null) {
    if (activeTargetFeedId.value !== feedId) {
      if (null !== feedId) {
        activeTargetFeedId.value = feedId;
        activeTargetFiles.value = targets;
        return;
      }
    }

    activeTargetFeedId.value = null;
    activeTargetFiles.value = [];
  }

  function hasRule(ruleId) {
    const filterRule = submitterRules.value.filter((rule) => rule.ruleId === ruleId);
    return 0 < filterRule.length;
  }

  function sortRules() {
    const userRules = submitterRules.value.filter((val) => val.userId);
    const defaultRules = submitterRules.value.filter((val) => !val.userId);
    submitterRules.value = userRules.concat(defaultRules);
  }

  function getSubmitFiles() {
    return submitterRules.value.filter((val) => val.submitFile);
  }

  function geUsertSubmitRules() {
    return submitterRules.value.filter((val) => val.userId);
  }
  function geUsertSubmitFiles() {
    const submiFiles = [];
    const userSubmitRules = geUsertSubmitRules();
    for (const rule of userSubmitRules) {
      for (const file of submitterFiles.value) {
        const fileId = file.value ? file.value.id : file.id;
        if (rule.submitFile && rule.submitFile.id === fileId) {
          submiFiles.push(file);
        }
      }
    }

    return submiFiles;
  }
  function getSubmitterFiles() {
    return submitterFiles.value.filter(
      (val) => val.userId === projectMembersStore().currentSubmitter.user.id
    );
  }

  function setTargets(srcTargets) {
    activeTargetFiles.value = srcTargets;
  }

  function deleteAttachedFile(deletedFileId) {
    attachedFiles.value = attachedFiles.value.filter((file) => {
      if (file.id === deletedFileId) {
        return null;
      } else {
        return true;
      }
    });
  }

  return {
    attachedFiles,
    submitterRules,
    submitterFiles,
    activeTargetFiles,

    apiGetAttachedFiles,
    apiGetSubmitter,
    apiUpdateDriveFile,
    apiDeleteDriveFiles,

    addDriveFile,
    deleteDriveFile,
    isSameFileName,
    setActiveTarget,
    hasRule,
    getSubmitFiles,
    geUsertSubmitRules,
    geUsertSubmitFiles,
    getSubmitterFiles,
    setTargets,
    deleteAttachedFile,
  };
});
