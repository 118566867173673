import axios from "@/modules/axios";

/*******************************************************************
 * POST 빌링키 생성
 *******************************************************************/
export function requestCreateBillingKey(groupId, customerKey, authKey) {
  return axios({
    url: `/groups/${groupId}/payment/billing`,
    method: "post",
    data: {
      customerKey,
      authKey,
    },
  });
}

/*******************************************************************
 * GET 결제 수단
 *******************************************************************/
export function requestGetBillingKey(groupId) {
  return axios({
    url: `/groups/${groupId}/payment/billing`,
    method: "get",
  });
}

/*******************************************************************
 * DELETE 결제 수단
 *******************************************************************/
export function requestDeleteBillingKey(groupId) {
  return axios({
    url: `/groups/${groupId}/payment/billing`,
    method: "delete",
  });
}

/*******************************************************************
 * POST 자동 결제
 *******************************************************************/
export function requestApproveBilling(groupId, plan) {
  return axios({
    url: `/groups/${groupId}/payment/billing-approve`,
    method: "post",
    data: {
      plan,
    },
  });
}

/*******************************************************************
 * GET 결제 이력
 *******************************************************************/
export function requestGetReceipts(groupId) {
  return axios({
    url: `/groups/${groupId}/payment/receipts`,
    method: "get",
  });
}

export default {
  requestCreateBillingKey,
  requestGetBillingKey,
  requestDeleteBillingKey,
  requestApproveBilling,
  requestGetReceipts,
};
