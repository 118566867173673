export const ENVIRONMENT = {
  development: "development",
  production: "production",
};

export const COOKIE_KEY = {
  token: "token",
  inviteCode: "dowple_invite_code",
  redirect: "dowple_redirect",
  feedbackTargets: "dowple_feedback_targets",
  linkId: "dowple_link_id",
  latestDomain: "dowple_latest_domain",
  firstJoinProject: "dowle_first_join_project",
};

export const WINDOW_EVENT = {
  signIn: "signin",
  redirect: "redirect",
};

export const USER_ROLE_TYPE = {
  superAdmin: "master",
  owner: "owner",
  administrator: "admin",
  member: "member",
  externalMember: "extMember",
};

export const PROGRESS_STATUS_TYPE = {
  beforeapply: "beforeapply",
  inprogress: "inprogress",
  modified: "modify",
  completed: "completed",
  failed: "fail",
};

export const FILE_UPLOAD_TYPE = {
  ruleSubmit: "submit_rule",
  driveSubmit: "submit_drive",
  attachmentSubmit: "submit_attach",
};

export const SUBMISSION_ROW_TYPE = {
  submitter: "submitter",
  unauthorizedSubmitter: "newSubmitter",
  file: "file",
  rule: "rule",
};

export const SUBSCRIPTION_PLAN_TYPE = {
  free: "free",
  basic: "basic",
  standard: "standard",
  premium: "premium",
};

export const UPLOAD_SIZE_LIMIT = {
  free: 3145728,
  basic: 104857600,
  standard: 209715200,
  premium: 314572800,
};

// export const UPLOAD_SIZE_LIMIT = {
//   free: 3145,
//   basic: 104857600,
//   standard: 209715200,
//   premium: 314572800,
// };

export const EVENT_TYPE = {
  paymentReadyCancel: "DanalPayReadyCancel",
  paymentBillSubmit: "DanalPayBillSubmit",

  groupDelegation: "delegate_group",

  projectCreated: "project_created",
  projectUpdated: "project_updated",
  projectDeleted: "project_deleted",
  projectInviteSent: "project_invite",
  projectDelegation: "project_delegate",

  feedbackCreated: "feed_created",
  feedbackUpdated: "feed_updated",
  feedbackDeleted: "feed_deleted",
  feedbackNotification: "feed_alarm",

  driveCreated: "drive_created",
  driveUpdated: "drive_updated",
  driveFileDeleted: "drive_file_deleted",
  driveFileUpdated: "drive_file_updated",
  driveZipSuccess: "drive_zip_successed",
  driveZipFailure: "drive_zip_fail",

  memberCreated: "member_created",
  memberUpdated: "member_updated",
  memberDeleted: "member_deleted",
};

export const globalNavigationHeight = 80;

export const DATE_FORMAT = {
  FileDateFormat: "YYYY.MM.DD HH:mm",
  MessageTodayDateFormat: "YYYY년 MM월 DD일",
  DeadlineDateFormat: "YYYY.MM.DD",
  MessageDateFormat: "HH:mm",
};

export default {
  globalNavigationHeight,

  ENVIRONMENT,
  COOKIE_KEY,
  WINDOW_EVENT,
  USER_ROLE_TYPE,
  PROGRESS_STATUS_TYPE,
  FILE_UPLOAD_TYPE,
  SUBMISSION_ROW_TYPE,
  SUBSCRIPTION_PLAN_TYPE,
  UPLOAD_SIZE_LIMIT,
  EVENT_TYPE,
  DATE_FORMAT,
};
