import moment from "moment";
import tippy from "tippy.js";
import {toast} from "vue3-toastify";

/******************************************************
 * 랜덤 숫자
 *****************************************************/
export const generateRandomNumber = (length) => {
  let value = "";
  for (let i = 0; i < length; i++) {
    let num = Math.floor(Math.random() * 9 + 0);
    if (0 == num) {
      i--;
    } else {
      value = value.concat(num.toString());
    }
  }

  return value;
};

/******************************************************
 * 랜덤 숫자
 ******************************************************/
export const generateRandomHexString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

/******************************************************
 * URL 쿼리에서 값 로드
 *****************************************************/
export const ConsolelogTypes = {
  Log: 0,
  Error: 1,
  Debug: 2,
};

/******************************************************
 * 로고
 *****************************************************/
export const csConsolelog = (value, type = 0) => {
  if (constants.ENVIRONMENT.production !== import.meta.env.VITE_NODE_ENV) {
    if (ConsolelogTypes.Error === type) {
      console.error(value);
    } else if (ConsolelogTypes.Debug === type) {
      console.debug(value);
    } else {
      console.log(value);
    }
  }
};

/******************************************************
 * 확장자에서 파일 이미지 선택
 *****************************************************/
import IconDOC from "@/assets/icons/files/doc.png";
import IconHWP from "@/assets/icons/files/hwp.png";
import IconIMAGE from "@/assets/icons/files/image.png";
import IconMUSIC from "@/assets/icons/files/music.png";
import IconPDF from "@/assets/icons/files/pdf.png";
import IconPPT from "@/assets/icons/files/powerpoint.png";
import IconTEXT from "@/assets/icons/files/text.png";
import IconVideo from "@/assets/icons/files/video.png";
import IconXLS from "@/assets/icons/files/xls.png";
import IconZIP from "@/assets/icons/files/zip.png";
import constants from "./constants";

export const getFileIcon = (ext) => {
  const exts = {
    avi: ["avi"],
    doc: ["doc", "docx"],
    hwp: ["hwp", "hwpx"],
    jpg: ["gif", "jpeg", "jpg"],
    m4v: ["m4v"],
    mp4: ["mp4"],
    pdf: ["pdf"],
    png: ["png"],
    ppt: ["ppt"],
    pptx: ["pptx"],
    psd: ["psd"],
    rar: ["alz", "rar", "ace", "egg"],
    txt: ["txt", "rtf"],
    wav: ["wav"],
    xls: ["xls"],
    xlsx: ["xlsx"],
    zip: ["zip"],
  };
  let result = null;
  const keys = Object.keys(exts);
  for (let i = 0; i < keys.length; i++) {
    const subKeys = exts[keys[i]];
    for (let j = 0; j < subKeys.length; j++) {
      if (ext === subKeys[j]) {
        result = keys[i];
        continue;
      }
    }
  }

  const icons = {
    avi: IconVideo,
    doc: IconDOC,
    hwp: IconHWP,
    jpg: IconIMAGE,
    m4v: IconVideo,
    mp4: IconVideo,
    pdf: IconPDF,
    png: IconIMAGE,
    ppt: IconPPT,
    ppt: IconPPT,
    pptx: IconPPT,
    psd: IconIMAGE,
    rar: IconZIP,
    txt: IconTEXT,
    wav: IconMUSIC,
    xls: IconXLS,
    xlsx: IconXLS,
    zip: IconZIP,
  };

  return result ? icons[result] : IconTEXT;
};

/******************************************************
 * 클립보드 복사
 ******************************************************/
export const copyToClipboard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    // Clipboard API 사용
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.warn("링크 복사", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        toast.warn("링크 복사 실패", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  } else {
    // Fallback 방법
    fallbackCopyText(text);
  }
};

function fallbackCopyText(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // 스타일 설정으로 textarea를 화면에서 숨김
  textArea.style.position = "fixed";
  textArea.style.top = "-1000px";
  textArea.style.left = "-1000px";

  document.body.appendChild(textArea);

  // 텍스트 선택
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    if (successful) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error("Fallback: 클립보드 복사 실패:", err);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

/******************************************************
 * 클립보드 복사
 ******************************************************/
export const fallbackCopyToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // 화면에서 보이지 않도록 스타일 적용
  textArea.style.position = "fixed";
  textArea.style.opacity = "0";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    if (successful) {
      toast.warn("링크 복사", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.warn("링크 복사 실패", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  } catch (err) {
    console.error("Fallback 복사 실패:", err);
  }

  document.body.removeChild(textArea);
};

/******************************************************
 * 베이스 URL
 ******************************************************/
export const getBaseWebUrl = () => {
  const url =
    import.meta.env.VITE_APP_BASE_WEB_PROTOCOL +
    "://www" +
    "." +
    import.meta.env.VITE_APP_BASE_WEB_URL_PATH;

  return url;
};

/******************************************************
 * 서브 도메인
 ******************************************************/
export const getSubdomain = () => {
  const host = window.location.hostname; // 포트 제거
  const parts = host.split(".");
  const domainLength = 2; // 기본적으로 도메인+TLD가 2개라 가정

  // 서브도메인이 있는 경우 추출
  if (parts.length > domainLength) {
    return parts[0]; // 서브도메인 부분만 반환
  }

  // 서브도메인이 없는 경우
  return null;
};

/******************************************************
 * 서브 도메인 URL
 ******************************************************/
export const createSubdomainUrl = (domain) => {
  const url =
    import.meta.env.VITE_APP_BASE_WEB_PROTOCOL +
    "://" +
    domain +
    "." +
    import.meta.env.VITE_APP_BASE_WEB_URL_PATH;

  return url;
};

/******************************************************
 * URL 쿼리에서 값 로드
 *****************************************************/
export const parseQueryParams = (search) => {
  var a = search.substr(1).split("&");
  if (a == "") return {};
  var b = {};
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split("=", 2);
    if (p.length == 1) b[p[0]] = "";
    else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return b;
};

/******************************************************
 * 날짜 형식 변환
 ******************************************************/
export const calculationDDay = (endData) => {
  if (!endData) {
    return "-";
  }

  let sdt = new Date(Date.now());
  let edt = new Date(endData);
  let dateDiff = Math.ceil((edt.getTime() - sdt.getTime()) / (1000 * 3600 * 24));
  return dateDiff;
};

export const showTippy = (element, message, trigger = "manual", instance = null) => {
  if (instance) {
    instance.destroy();
  }

  const tooltip = tippy(element, {
    theme: "warn",
    allowHTML: true,
    content: '<span style="display:flex; line-height: 1.34rem;">' + message + "</span>",
    trigger: trigger, // 버튼을 클릭해야 툴팁이 표시되도록 설정
    appendTo: document.body,
    hideOnClick: true, // 툴팁 외부 클릭 시 사라짐
    interactive: true, // 툴팁 내부에서 클릭 가능하게 설정,
  });

  if ("manual" === trigger) {
    tooltip[0].show();
  }

  return tooltip[0];
};

export default {
  ConsolelogTypes,

  generateRandomNumber,
  generateRandomHexString,
  csConsolelog,
  getFileIcon,
  copyToClipboard,
  fallbackCopyToClipboard,
  getBaseWebUrl,
  getSubdomain,
  createSubdomainUrl,
  parseQueryParams,
  calculationDDay,
  showTippy,
};
