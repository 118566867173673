import axios from "@/modules/axios";

/*******************************************************************
 * Social Login APIs
 *******************************************************************/
export function loginWithKakao() {
  return axios({
    url: "/account/signin/kakao",
    method: "get",
  });
}

export function loginKakaoCallback(data) {
  return axios({
    url: "/account/signin/kakao-callback",
    method: "post",
    data: data,
  });
}

export function loginWithGoogle(data) {
  return axios({
    url: "/account/signin/google",
    method: "post",
    data: data,
  });
}

export function loginWithNaver(data) {
  return axios({
    url: "/account/signin/naver",
    method: "post",
    data: data,
  });
}

/*******************************************************************
 * Account Management APIs
 *******************************************************************/
export function createAccount(user) {
  return axios({
    url: "/account/signup/email",
    method: "post",
    params: {
      noToast: 0,
    },
    data: {
      user: user,
    },
  });
}

export function login(email, password) {
  email = email.trim().normalize();
  password = password.trim().normalize();

  return axios({
    url: "/account/signin",
    method: "post",
    params: {
      noToast: 0,
    },
    data: {
      email: email,
      password: password,
    },
  });
}

export function verifyEmailCode(code) {
  return axios({
    url: "/account/signup/confirm-code",
    method: "post",
    data: {
      code: code,
    },
  });
}

export function recoverPassword(email) {
  return axios({
    url: "/account/find-password",
    method: "post",
    params: {
      noToast: 0,
    },
    data: {
      email: email,
    },
  });
}

export function updatePassword(code, newPassword) {
  return axios({
    url: "/account/update-password",
    method: "put",
    params: {
      noToast: 0,
    },
    data: {
      code: code,
      newPassword: newPassword,
    },
  });
}

/*******************************************************************
 * 사용자 정보 조회
 *******************************************************************/
export function getUserData() {
  return axios({
    url: `/user`,
    method: "get",
  });
}

/*******************************************************************
 * 사용자 썸네일 업로드
 *******************************************************************/
export function uploadUserThumbnail() {
  return axios({
    url: `/user/thumbnail`,
    method: "post",
  });
}

/*******************************************************************
 * 사용자 썸네일 삭제
 *******************************************************************/
export function deleteUserThumbnail() {
  return axios({
    url: `/user/thumbnail`,
    method: "delete",
  });
}

/*******************************************************************
 * 사용자 정보 수정
 *******************************************************************/
export function updateUserData(userData) {
  return axios({
    url: `/user`,
    method: "put",
    data: userData,
  });
}

/*******************************************************************
 * 사용자 탈퇴
 *******************************************************************/
export function withdrawUserAccount(email) {
  return axios({
    url: `/account/withdrawal`,
    method: "post",
    data: {
      email: email,
    },
  });
}

/**
 * 그룹 초대요청 리스트
 * @param {*} groupId
 * @returns
 */
export function getMyGroupInvites() {
  return axios({
    url: `/user/invite-group`,
    method: "get",
  });
}

/**
 * 그룹 초대요청 승인
 * @param {*} groupId
 * @returns
 */
export function confirmMyGroupInvite(inviteId) {
  return axios({
    url: `/user/invite-group`,
    method: "post",
    data: {
      inviteId,
    },
  });
}

/**
 * 그룹 초대요청 거절
 * @param {*} groupId
 * @returns
 */
export function refuseMyGroupInvite(inviteId) {
  return axios({
    url: `/user/invite-group`,
    method: "delete",
    params: {
      inviteId,
    },
  });
}

/*******************************************************************
 * Default Export
 *******************************************************************/
export default {
  loginWithKakao,
  loginKakaoCallback,
  loginWithGoogle,
  loginWithNaver,
  createAccount,
  login,
  verifyEmailCode,
  recoverPassword,
  updatePassword,

  getUserData,
  updateUserData,
  uploadUserThumbnail,
  deleteUserThumbnail,
  withdrawUserAccount,
  getMyGroupInvites,
  confirmMyGroupInvite,
  refuseMyGroupInvite,
};
