<script setup>
import {onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {HttpStatusCode} from "axios";
import utils from "@/common/utils";
import {Modal, ModalComponents, CheckModule} from "@/modules";
import {groupStore, projectStore, submitStore} from "@/stores";
// Component
import {toast} from "vue3-toastify";
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const {rules} = storeToRefs(submitStore());
const check = ref(CheckModule());

const emits = defineEmits(["close"]);
const submitButtonRef = ref();
let tooltip = null;

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(() => {
  check.value.initializeCheckedList(rules.value.length);
});

/**********************************************************
 * Event Handlers
 *********************************************************/
// 모달 닫기
function closeModal() {
  emits("close", {status: false});
}

// 프로젝트 생성
async function handleSubmit(done) {}
</script>

<template>
  <div class="md:w-[36rem]">
    <div class="modal-title">제출자료 선택 다운로드</div>
    <button class="modal-btn-close" @click="closeModal">
      <font-awesome-icon icon="xmark" class="text-lg" />
    </button>

    <div class="modal-content">
      <validation-message
        type="info"
        :isShowIcon="false"
        :texts="['선택한 제출자료만 다운로드 합니다.']"
      ></validation-message>
      <ul class="h-[20rem] mt-gap-group">
        <li
          v-for="(rule, idx) of rules"
          :key="idx"
          class="mb-gap-item border border-c-layout rounded-md bg-gray-50"
        >
          <label :for="'rule' + idx" class="flex items-center p-gap-group cursor-pointer">
            <div class="mr-gap-group">
              <input
                :id="'rule' + idx"
                class="form-check-input"
                type="checkbox"
                @change.stop="check.toggleItem(idx)"
              />
            </div>
            <div>{{ rule.title }}</div>
          </label>
        </li>
      </ul>

      <div class="modal-footer mt-gap-section">
        <loader-button
          ref="submitButtonRef"
          class="mt-gap-group"
          :customCSS="'modal-confirm'"
          @request="handleSubmit"
        >
          다운로드
        </loader-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cs-content {
  @apply min-h-screen pt-[60px] pb-10 px-5 md:px-[10rem];
  @apply rounded-t-sm;
  @apply bg-white;
}
</style>
