<script setup>
import {onMounted, onUnmounted, watch, ref, provide} from "vue";
import {storeToRefs} from "pinia";
import constants from "@/common/constants.js";
import utils from "@/common/utils.js";
import CsSocket from "@/modules/socket.js";
import {Modal, fileDownload} from "@/modules";
import {commonStore, userStore, groupStore} from "@/stores";
import {useRouter, useRoute} from "vue-router";
// Components
import modal from "@/views/modal/modal.vue";
import ZipStatus from "@/views/components/common/zip-status.vue";
import {axiosAppData} from "./modules/axios";

/**
 * Datas
 */
const {userId} = storeToRefs(userStore());
const {zipDownloads} = storeToRefs(commonStore());

/**
 * Lifecycle Hooks
 */
onMounted(() => {
  // Connect to the socket server when the component is mounted
  CsSocket.connect();

  axiosAppData.groupStore = storeToRefs(groupStore());
  axiosAppData.router = useRouter();
});

onUnmounted(() => {
  // Disconnect from the socket server when the component is unmounted
  CsSocket.disconnect();
});

/**
 * Watchers
 */
watch(
  () => userId.value,
  () => {
    CsSocket.join(userId.value);
    CsSocket.onEvent("update_common", async (data) => {
      utils.Common.csConsolelog(`Received project update event: ${data}`);

      if (data.event === constants.EVENT_TYPE.driveZipSuccess) {
        fileDownload(data.value.url, decodeURI(data.value.name));
        setTimeout(() => {
          commonStore().removeZipDownload(data.value.zipId);
        }, 1000);
      }
    });
  }
);

/**
 * Theme Management
 */
function applyTheme(isDarkMode) {
  const theme = isDarkMode ? "dark" : "light";
  document.body.dataset.theme = theme;
}

const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
applyTheme(darkModeMediaQuery.matches);
darkModeMediaQuery.addEventListener("change", (e) => applyTheme(e.matches));

/**
 * Window Event Listeners
 */
window.addEventListener("message", (event) => {
  const {event: eventType} = event.data;

  if (eventType === constants.WINDOW_EVENT.signIn) {
    // Handle sign-in event and redirect to the main page
    if (window.opener) {
      window.opener.postMessage({event: constants.WINDOW_EVENT.signIn}, "*");
      window.close();
    } else {
      window.location.href = import.meta.env.VITE_APP_BASE_WEB_URL;
    }
  } else if (eventType === constants.WINDOW_EVENT.redirect) {
    // Handle redirect event
    const redirectUrl = utils.Cookie.getCookie(constants.COOKIE_KEY.redirect);
    utils.Cookie.removeCookie(constants.COOKIE_KEY.redirect);
    window.location.href = redirectUrl;
  }
});

// 뷰포트 높이의 1% 값을 계산해서 CSS 변수에 저장
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

// 창 크기가 변할 때마다 업데이트
// window.addEventListener("resize", () => {
//   console.log("window event resize");
//   vh = window.innerHeight * 0.01;
//   document.documentElement.style.setProperty("--vh", `${vh}px`);
// });

window.addEventListener("load", function () {
  console.log(document.body.scrollHeight, window.innerHeight);

  // console.log("window event load");
  // const centerScroll = (document.documentElement.scrollHeight - window.innerHeight) / 2;
  // console.log(
  //   "window event load: " + document.documentElement.scrollHeight + "/" + window.innerHeight
  // );
  // console.log("window event load: " + centerScroll);
  // window.scrollTo({top: centerScroll, behavior: "smooth"});
});

function setMaxHeight() {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
}

window.addEventListener("resize", setMaxHeight);
setMaxHeight();
</script>

<template>
  <div class="cs-layout full-screen">
    <router-view />

    <div class="zip-container">
      <zip-status
        v-for="(zip, idx) in zipDownloads"
        :key="idx"
        :zipId="zip.zipId"
        :zipName="zip.zipName"
      ></zip-status>
    </div>

    <modal v-if="Modal.isVisible" :width="Modal.modalWidth">
      <slot>
        <component
          :is="Modal.activeModal"
          :params="Modal.modalParams"
          @close="(result) => Modal.onCloseModal(result)"
        />
      </slot>
    </modal>

    <div
      v-if="false"
      class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 flex flex-col items-center justify-center"
    >
      <div
        class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
      ></div>
      <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
      <p class="w-1/3 text-center text-white">
        This may take a few seconds, please don't close this page.
      </p>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/index.scss";

// .full-screen {
//   max-height: calc(var(--vh) * 100);
//   // // min-height: 1px;
//   // // min-height: 100vh;
//   // min-height: 600px;
//   // max-height:;
// }

.zip-container {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
</style>
