import {ref, computed} from "vue";
import {defineStore} from "pinia";
import axios, {HttpStatusCode} from "axios";
import apis from "@/apis";
import {commonStore, groupStore, projectStore} from "@/stores";
import utils from "@/common/utils";
import {rule} from "postcss";

export const submitStore = defineStore("submit", () => {
  // 제출자료 규칙
  const rules = ref([]);

  // $reset 메서드 구현
  function $reset() {
    rules.value = [];
  }

  async function apiGetRules() {
    try {
      const res = await apis.project.getSubmitRules(groupStore().groupId, projectStore().projectId);
      rules.value = res.data.data;

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiCreateRule(title, userId = undefined) {
    try {
      const res = await apis.project.addSubmitRules(
        groupStore().groupId,
        projectStore().projectId,
        {title, userId}
      );

      rules.value = rules.value.concat(res.data.data);

      for (let i = 0; i < rules.value.length; i++) {
        rules.value[i].rowIdx = i;
      }

      projectStore().setRuleCount(rules.value.length);

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiUpdateRules(updateRules) {
    try {
      const res = await apis.project.updateSubmitRules(
        groupStore().groupId,
        projectStore().projectId,
        updateRules
      );

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiDeleteRule(ruleId) {
    try {
      const res = await apis.project.deleteSubmitRule(
        groupStore().groupId,
        projectStore().projectId,
        ruleId
      );

      deleteRowRule(ruleId);
      projectStore().setRuleCount(rules.value.length);

      for (let i = 0; i < rules.value.length; i++) {
        rules.value[i].rowIdx = i;
      }

      apiUpdateRules(rules.value);

      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  function addSubmitRule(title) {
    rules.value.push({title: title, files: [], rowIdx: rules.value.length});
  }

  function changeIndexSubmitRules(oldIdx, newIdx) {
    if (0 > newIdx) {
      return;
    }
    if (rules.value.length <= newIdx) {
      return;
    }

    const [movedItem] = rules.value.splice(oldIdx, 1);
    rules.value.splice(newIdx, 0, movedItem);

    for (let i = 0; i < rules.value.length; i++) {
      rules.value[i].rowIdx = i;
    }

    rules.value = [...rules.value];
  }

  function deleteRowRule(ruleId) {
    let rowIdx = 0;
    for (let i = 0; i < rules.value.length; i++) {
      if (ruleId === rules.value[i].ruleId) {
        rowIdx = i;
      }
    }

    rules.value.splice(rowIdx, 1);
    rules.value = rules.value.sort((a, b) => a.rowIdx - b.rowIdx);
  }

  function getOriginSubmitRuleCount() {
    return rules.value.filter((rule) => {
      return rule.userId && 0 < rule.userId ? false : true;
    }).length;
  }

  function deletedAttachedFile(deletedFileId) {
    for (const rule of rules.value) {
      let idx = 0,
        isHas = false;
      for (const file of rule.files) {
        if (file.id === deletedFileId) {
          isHas = true;
          break;
        }
        idx++;
      }

      if (isHas) {
        rule.files.splice(idx, 1);
      }
    }
  }

  //------------------------
  function updateFile(targetFile) {
    // 파일 배열을 찾아 name 변경
    submitters.value.forEach((item) => {
      if (item.data.user.id === targetFile.userId) {
        if (item.data && item.data.files) {
          // files 배열에서 targetFile 같은 id를 가진 파일 찾기
          const fileIndex = item.data.files.findIndex((file) => file.id === targetFile.id);
          if (fileIndex !== -1) {
            // 같은 id가 있다면 해당 파일 속성 업데이트
            item.data.files[fileIndex].name = targetFile.name;
            item.data.files[fileIndex].rule = targetFile.rule;
            item.data.files[fileIndex].useState = targetFile.useState;
          } else {
            // 같은 id가 없다면 files 배열에 targetFile 추가
            item.data.files.push(targetFile);
          }
        }
      }
    });
  }

  function getUnFeedbackRules(userId) {
    const submitter = submitters.value.find((submitter) => submitter.data.user.id === userId);
    if (submitter) {
      const unFeedbackRules = submitter.children.filter((row) => {
        if (SubmitRowType.Rule === row.type) {
          if (undefined === row.data.userId || null === row.data.userId || !row.data.userId) {
            // if (!row.data.isFeedback) {
            //   return true;
            // }
          } else {
            if (userId === row.data.userId && !row.data.isFeedback && row.data.submitFile) {
              return true;
            }
          }
        }
      });

      return unFeedbackRules;
    }

    return null;
  }

  function getUserSubmitRuleCount(userId) {
    const userCount = rules.value.filter((rule) => {
      return userId === rule.userId ? true : false;
    }).length;

    return userCount;
  }

  function getUserAllSubmitRuleCount(userId) {
    const originCount = getOriginSubmitRuleCount();
    const userCount = rules.value.filter((rule) => {
      return userId === rule.userId ? true : false;
    }).length;

    return originCount + userCount;
  }

  function isUserSubmitRuleCompleted(userId) {
    const userCount = rules.value.filter((rule) => {
      return userId === rule.userId ? true : false;
    }).length;

    let completedRuleCount = 0;
    if (submitters.value && submitters.value.length) {
      for (const submiter of submitters.value) {
        completedRuleCount = submiter.children.filter((row) => {
          return userId === row.data.userId && row.data.submitFile ? true : false;
        }).length;
      }
    }

    return userCount === completedRuleCount;
  }

  function deleteUploadingFile(submitterUserId, fileName) {
    for (const submitter of submitters.value) {
      if (submitterUserId === submitter.data.user.id) {
        if (submitter.data && submitter.data.files) {
          submitter.data.files = submitter.data.files.filter(
            (file) => file.name.normalize() !== fileName.normalize()
          );
        }
      }
    }
  }

  return {
    rules,

    $reset,

    apiGetRules,
    apiCreateRule,
    apiUpdateRules,
    apiDeleteRule,

    addSubmitRule,
    changeIndexSubmitRules,
    deleteRowRule,
    getOriginSubmitRuleCount,
    deletedAttachedFile,
  };
});
