import constants from "@/common/constants";
import utils from "@/common/utils";

/******************************************************
 * URL 쿼리에서 값 로드
 *****************************************************/
const setCookie = (name, value, options = {}) => {
  if (constants.ENVIRONMENT.production !== import.meta.env.VITE_NODE_ENV) {
    utils.Common.csConsolelog("set coookie - name: " + name + " / value: " + value);
  }

  options = {
    path: "/",
    domain: `.${import.meta.env.VITE_APP_BASE_WEB_URL_COOKIE}`,
    // 필요한 경우, 옵션 기본값을 설정할 수도 있습니다.
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;

  // SameSite Secure https에서 다른 쿠키 못 가져와서 오류
  // if ("production" === import.meta.env.VITE_NODE_ENV) {
  //   document.cookie = updatedCookie + "SameSite=None; Secure;";
  // } else {
  //   document.cookie = updatedCookie;
  // }
};

/******************************************************
 * URL 쿼리에서 값 로드
 *****************************************************/
const getCookie = (key) => {
  let value = sessionStorage.getItem(key);

  if (!value) {
    value = localStorage.getItem(key);
    if (!value) {
      value = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
      if (value) {
        return decodeURIComponent(value[2]);
      } else {
        value = null;
      }
    }
  }

  return value;
};

/******************************************************
 * URL 쿼리에서 값 로드
 *****************************************************/
const removeCookie = (name) => {
  utils.Common.csConsolelog("remove cookie ---------------");
  setCookie(name, "", {
    domain: "." + import.meta.env.VITE_APP_BASE_WEB_URL_COOKIE,
    "max-age": -1,
  });
  utils.Common.csConsolelog("remove cookie ---------------");

  sessionStorage.removeItem(name);
  localStorage.removeItem(name);
};

export default {
  setCookie,
  getCookie,
  removeCookie,
};
