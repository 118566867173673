import account from "./account";
import group from "./group";
import project from "./project";
import feedback from "./feedback";
import payment from "./payment";

export default {
  account,
  group,
  project,
  feedback,
  payment,
};
