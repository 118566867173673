import {ref, computed} from "vue";
import {defineStore} from "pinia";

import API from "@/apis";
import utils from "@/common/utils";
import {groupStore} from "@/stores";
import axios, {AxiosError} from "axios";

export const projectsStore = defineStore("projects", () => {
  const projects = ref([]);

  /****************************************************
   * 프로젝트 생성
   ****************************************************/
  async function createProject(projectName) {
    try {
      const res = await API.project.createProject(groupStore().groupId, projectName.trim());
      projects.value.unshift(res.data.data);
      return res;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      return error;
    }
  }

  /****************************************************
   * 프로젝트 리스트
   ****************************************************/
  async function getProjects() {
    try {
      const res = await API.project.getProjectList(groupStore().groupId);
      projects.value = res.data.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      return error;
    }
  }

  /****************************************************
   * 해당 프로젝트 정보 수정
   ****************************************************/
  function modifyProjectName(projectId, projectName) {
    projects.value.forEach((project) => {
      if (projectId === project.id) {
        project.name = projectName;
      }
    });
  }

  /****************************************************
   * 프로젝트 리스트에서 삭제
   ****************************************************/
  function deleteProject(projectId) {
    projects.value = projects.value.filter((project) => project.id !== projectId);
  }

  /****************************************************
   * 프로젝트 리스트에서 같은 이름 검색
   ****************************************************/
  function isExistProjectName(projectName) {
    const existProjects = projects.value.filter((project) => {
      if (projectName === project.projectName) {
        return project;
      }
    });

    if (existProjects.length) {
      return true;
    }

    return false;
  }

  function changeProjectData(
    projectId,
    projectName = null,
    projectProgressStatus = null,
    externalMembersCount = null,
    completedExternalMembersCount = null,
    newNotificationCount = null
  ) {
    for (const project of projects.value) {
      if (project.id === projectId) {
        if (projectName) project.name = projectName;
        if (projectProgressStatus) project.progressStatus = projectProgressStatus;
        if (externalMembersCount) project.externalMembersCount = externalMembersCount;
        if (completedExternalMembersCount)
          project.completedExternalMembersCount = completedExternalMembersCount;
        if (newNotificationCount) project.newNotificationCount = newNotificationCount;

        break;
      }
    }
  }

  return {
    projects,

    createProject,
    getProjects,
    modifyProjectName,
    deleteProject,
    isExistProjectName,
    changeProjectData,
  };
});
