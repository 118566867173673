<script setup>
import {ref} from "vue";

const props = defineProps({
  customCSS: {
    type: String,
    default: null,
    required: false,
  },
  isShowPending: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["request"]);
const isRequestPending = ref(false);

const onClickLoaderBtn = async () => {
  if (isRequestPending.value) return;
  isRequestPending.value = true;

  try {
    emit("request", () => {
      isRequestPending.value = false;
    });
  } catch (error) {
    console.error("요청 실패:", error);
    isRequestPending.value = false;
  }
};

defineExpose({onClickLoaderBtn});
</script>

<template>
  <button
    class="cs-button"
    :class="[props.customCSS, isShowPending && isRequestPending ? 'bg-gray-100' : '']"
    :disabled="isRequestPending"
    @click.stop="onClickLoaderBtn"
  >
    <!-- <div class="absolute top-1/2 left-10 -translate-y-1/2 w-5 h-5">
      <div
        v-if="isShowSpiner && isRequestPending"
        class="loader-spinner ease-linear rounded-full border-2 border-gray-200 border-t-blue-500 w-5 h-5 mr-gap-group"
      ></div>
    </div> -->
    <template v-if="isShowPending && isRequestPending">
      <slot name="pending"></slot>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<style lang="scss" scoped>
.cs-button {
  @apply relative overflow-hidden rounded-md;
}
</style>
