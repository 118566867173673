<script setup>
import {ref, computed, onMounted, watch, defineEmits} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import utils from "@/common/utils";
import {groupStore} from "@/stores";
import LabelInput from "@/components/label-input.vue";
import ModuleLinkSharing from "@/views/modal/link-sharing/link-sharing";

/**********************************************************
 * Reactive Variables
 *********************************************************/
defineExpose({fetchData, getReceivers, removeUser, clear});

const {groupId} = storeToRefs(groupStore());
const moduleLinkSharing = ref(ModuleLinkSharing());

const isLoading = ref(false);

const searchUserNameRef = ref();

onMounted(async () => {
  isLoading.value = true;
  await fetchData();
  isLoading.value = false;

  moduleLinkSharing.value.validateAddUserCB = validateAddUser;
  moduleLinkSharing.value.validateRemoveUserCB = validateRemoveUser;
});

async function fetchData(keyword = null) {
  try {
    const res = await apis.group.getGroupAllSubmitters(groupId.value, keyword);
    moduleLinkSharing.value.externalMembers = res.data.data;

    return true;
  } catch (error) {
    return false;
  }
}

async function handleSearchUser() {
  const keyword = searchUserNameRef.value.getText();
  await fetchData(keyword);
}

function validateAddUser(receivers, addUser) {
  if (!addUser.cellPhone) {
    return {
      status: false,
      message: "휴대전화 정보가 없는 제출자는 등록할 수 없습니다",
    };
  }

  const isSameUser = receivers.some((receiver) => receiver.cellPhone === addUser.cellPhone);
  if (isSameUser) {
    return {
      status: false,
      message: "이미 등록되어 있습니다 ",
    };
  }

  return {
    status: true,
  };
}
function validateRemoveUser(receiver, user) {
  return receiver.cellPhone === user.cellPhone;
}

function getReceivers() {
  return moduleLinkSharing.value.receivers;
}
function removeUser(user) {
  moduleLinkSharing.value.removeUser(user);
}
function clear() {
  searchUserNameRef.value.setText("");
  moduleLinkSharing.value.clear();
}
</script>

<template>
  <div>
    <div class="flex items-center h-[3.4rem]">
      <label-input
        id="searchUserName"
        ref="searchUserNameRef"
        class="w-full"
        type="text"
        placeholder-text="이름 검색"
        height="h-c-form-sm"
        @enter="handleSearchUser"
      >
        <template v-slot:after>
          <div class="w-10 m-auto">
            <button tabindex="-1" @click="handleSearchUser">
              <font-awesome-icon icon="magnifying-glass" class="text-lg" />
            </button>
          </div>
        </template>
      </label-input>
    </div>

    <div class="overflow-y-auto h-[20rem] max-h-[20rem] mt-gap-group relative">
      <template v-if="isLoading">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden flex flex-col items-center justify-center"
        >
          <div
            class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
          ></div>
        </div>
      </template>
      <template v-else>
        <ul v-if="moduleLinkSharing.getExternalMemberCount()" class="">
          <li
            v-for="(user, idx) in moduleLinkSharing.externalMembers"
            :key="idx"
            class="exist-user-row"
            @click="moduleLinkSharing.addUser(user)"
          >
            <label class="flex items-center w-full h-full cursor-pointer" :for="'exist' + idx">
              <font-awesome-icon icon="plus" class="mr-gap-group text-lg" />
              <span class="w-full cs-ellipsis1">{{ user.name }}</span>
              <span class="ml-gap-group min-w-[9rem] text-right text-c-normal">
                {{ utils.String.formatPhoneNumber(user.cellPhone) }}
              </span>
            </label>
          </li>
        </ul>
        <div v-else class="w-full mt-gap-group text-center text-c-dark">
          검색된 제출자가 없습니다.
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.exist-user-row {
  @apply flex relative items-center h-[3rem] p-gap-group border-b border-c-layout cursor-pointer transition-all;

  &:hover {
    @apply bg-c-selected-light;
  }
}
</style>
