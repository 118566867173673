<script setup>
import {ref, computed, onMounted, watch, defineEmits} from "vue";
import utils from "@/common/utils";
import LabelInput from "@/components/label-input.vue";
import ModuleLinkSharing from "@/views/modal/link-sharing/link-sharing";

/**********************************************************
 * Reactive Variables
 *********************************************************/
defineExpose({getReceivers, removeUser, clear});

const moduleLinkSharing = ref(ModuleLinkSharing());

const newUserNameRef = ref();
const newUserPhoneNumberRef = ref();

onMounted(async () => {
  moduleLinkSharing.value.validateAddUserCB = validateAddUser;
  moduleLinkSharing.value.validateRemoveUserCB = validateRemoveUser;
});

function handleAddNewUser() {
  let name = newUserNameRef.value.getText();
  let cellPhone = newUserPhoneNumberRef.value.getText();

  let validateResult = utils.Validate.validateName(name, true);
  if (!validateResult.status) {
    tooltip = utils.Common.showTippy("#newUserName", validateResult.message, undefined, tooltip);
    return;
  }
  validateResult = utils.Validate.validatePhone(cellPhone, true);
  if (!validateResult.status) {
    tooltip = utils.Common.showTippy(
      "#newUserPhoneNumber",
      validateResult.message,
      undefined,
      tooltip
    );
    return;
  }

  newUserNameRef.value.setFocus();
  newUserNameRef.value.setText("");
  newUserPhoneNumberRef.value.setText("");

  cellPhone = cellPhone.replace(/-/g, "");
  moduleLinkSharing.value.addUser({name: name, cellPhone: cellPhone});
}

function handleChangeCellPhone(result) {
  newUserPhoneNumberRef.value.setText(utils.String.formatPhoneNumber(result.value));
}

function validateAddUser(receivers, addUser) {
  if (!addUser.cellPhone) {
    return {
      status: false,
      message: "휴대전화 정보가 없는 제출자 입니다",
    };
  }

  const isSameUser = receivers.some((receiver) => receiver.cellPhone === addUser.cellPhone);
  if (isSameUser) {
    return {
      status: false,
      message: "이미 등록되어 있습니다 ",
    };
  }

  return {
    status: true,
  };
}
function validateRemoveUser(receiver, user) {
  return receiver.cellPhone === user.cellPhone;
}

function getReceivers() {
  return moduleLinkSharing.value.receivers;
}
function removeUser(user) {
  moduleLinkSharing.value.removeUser(user);
}
function clear() {
  moduleLinkSharing.value.clear();
}
</script>

<template>
  <div>
    <div class="flex items-center">
      <label-input
        id="newUserName"
        ref="newUserNameRef"
        class="w-full"
        type="text"
        placeholder-text="이름"
        :is-important="true"
        height="h-c-form-sm"
        @enter="handleAddNewUser"
      >
      </label-input>
      <label-input
        id="newUserPhoneNumber"
        ref="newUserPhoneNumberRef"
        class="w-full ml-gap-group"
        type="text"
        placeholder-text="휴대전화번호"
        :is-important="true"
        height="h-c-form-sm"
        @change="handleChangeCellPhone"
        @enter="handleAddNewUser"
      >
      </label-input>
    </div>
    <div class="">
      <button
        class="cs-btn cs-btn-primary w-full h-c-confirm mt-gap-group"
        @click="handleAddNewUser"
      >
        추가
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exist-user-row {
  @apply flex relative items-center h-[3rem] p-gap-group border-b border-c-layout cursor-pointer transition-all;

  &:hover {
    @apply bg-c-selected-light;
  }
}
</style>
