<script setup>
import {ref, computed, onMounted, watch, onUnmounted} from "vue";
import {storeToRefs} from "pinia";
import utils from "@/common/utils";
import {Modal, ModalComponents} from "@/modules";
import {driveStore, groupStore, projectMembersStore, projectStore} from "@/stores";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";

const {groupId, groupName} = storeToRefs(groupStore());
const {projectId, projectName, projectRulesCount} = storeToRefs(projectStore());

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
    default: null,
  },
});

const newNameRef = ref();
const submitButtonRef = ref();
let tooltip = null;

onMounted(() => {
  newNameRef.value.setFocus();

  if (props.params.name) {
    newNameRef.value.setText(props.params.name);
  }
});

/**********************************************************
 * 모달 닫기
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

function handleEnterChangeName() {
  submitButtonRef.value.onClickLoaderBtn();
}
async function handleChangeName(done) {
  const newName = newNameRef.value.getText();

  const validateName = utils.Validate.validateName(newName, true);
  if (!validateName.status) {
    tooltip = utils.Common.showTippy("#newNameInput", validateName.message, undefined, tooltip);
    done();
    return;
  }

  await projectMembersStore().apiUpdateSubmitterName(
    groupId.value,
    projectId.value,
    props.params.id,
    newName
  );

  done();
  closeModal();
}
</script>

<template>
  <div class="md:w-[32rem]">
    <div>
      <div class="modal-title">이름 변경</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content">
      <!-- 이름 ------------------------------------------------------>
      <label-input
        id="newNameInput"
        ref="newNameRef"
        class="w-full"
        type="text"
        placeholder-text="이름"
        :is-important="true"
        height="h-c-form"
        @enter="handleEnterChangeName"
      >
      </label-input>
      <validation-message
        type="desc"
        :texts="['제출함 내부에서 사용되는 이름이 변경됩니다.']"
      ></validation-message>

      <div class="modal-footer">
        <loader-button
          ref="submitButtonRef"
          :customCSS="'modal-confirm'"
          @request="handleChangeName"
        >
          <slot><span class="text-white">확인</span></slot>
        </loader-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
