<script setup>
import {ref, inject, onMounted, onUnmounted, nextTick} from "vue";
import utils from "@/common/utils.js";

const props = defineProps({
  initText: {
    type: String,
  },
  isChangeText: {
    type: Boolean,
    default: true,
  },
  items: {
    type: Array,
  },
  css: {
    type: String,
  },
  position: {
    type: Object,
    default: null,
  },
  menuCss: {
    type: String,
  },
});
const emits = defineEmits(["click", "hidden"]);
defineExpose({toggleDropdown});

const componentId = utils.Common.generateRandomNumber(5);
const isDropdownVisible = ref(false); // Track visibility of the dropdown
const dropdownText = ref(""); // Text to display in the dropdown button
const dropdownPosition = ref("left"); // Positioning for the dropdown (left or right)

onMounted(() => {
  dropdownText.value = props.initText; // Set initial text
  document.addEventListener("click", handleOutsideClick); // Listen for clicks outside the dropdown
});

onUnmounted(() => {
  document.removeEventListener("click", handleOutsideClick); // Clean up event listener
});

// Toggles the visibility of the dropdown menu
function toggleDropdown(event) {
  setTimeout(() => {
    isDropdownVisible.value = !isDropdownVisible.value;
    if (isDropdownVisible.value) {
      nextTick(() => {
        adjustDropdownPosition();
      });
    }
  }, 50);
}

// Handles item click within the dropdown
function handleMenuItemClick(item) {
  emits("click", item.data); // Emit the selected item data

  if (props.isChangeText) {
    dropdownText.value = item.text; // Change the button text if required
  }

  isDropdownVisible.value = false; // Close the dropdown after selection
}

// Closes the dropdown when clicking outside of it
function handleOutsideClick(event) {
  const dropdownMenu = document.querySelector("#dropmenu" + componentId);
  if (dropdownMenu && !dropdownMenu.contains(event.target)) {
    isDropdownVisible.value = false;
  }

  emits("hidden"); // Emit that the dropdown is hidden
}

// Adjusts the dropdown position to the right if it's too wide for the screen
function adjustDropdownPosition() {
  const dropdownMenu = document.querySelector("#dropmenu" + componentId);
  const dropdownList = dropdownMenu.querySelector(".dropdown-menu");

  if (dropdownMenu && dropdownList) {
    if (props.position) {
      dropdownPosition.value = "left"; // Otherwise, position to the left
      dropdownList.style.top = props.position.top + "px";
      dropdownList.style.left = props.position.left + "px";
    } else {
      const dropdownMenuRect = dropdownMenu.getBoundingClientRect();
      const dropdownListRect = dropdownList.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      if (dropdownMenuRect.right + dropdownListRect.width < viewportWidth) {
        dropdownPosition.value = "right"; // Position to the right if it exceeds the screen width
        dropdownList.style.top = dropdownMenuRect.top + "px";
        dropdownList.style.left = dropdownMenuRect.left + "px";
      } else {
        dropdownPosition.value = "left"; // Otherwise, position to the left
        dropdownList.style.top = dropdownMenuRect.top + "px";
        dropdownList.style.left = dropdownMenuRect.left - dropdownListRect.width + "px";
      }
    }
  }
}
</script>

<template>
  <div
    @click="toggleDropdown"
    :id="'dropmenu' + componentId"
    class="dropmenu-container"
    :class="css"
  >
    <button class="dropmenu-button">
      <slot name="before"></slot>
      {{ dropdownText }}
    </button>

    <div
      v-if="isDropdownVisible"
      class="dropdown-menu animate-slide-down"
      :class="[{'position-right': dropdownPosition === 'right'}, menuCss]"
      @click.stop
    >
      <ul v-if="props.items && props.items.length">
        <li
          v-for="(item, idx) in props.items"
          :key="idx"
          class="text-left text-c-default cs-ellipsis1"
          @click.stop="handleMenuItemClick(item)"
        >
          {{ item.text }}
        </li>
      </ul>

      <!-- <div v-else>
        <slot name="content"></slot>
      </div> -->
      <div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.dropmenu-container {
  @apply relative;
}
.dropmenu-button {
  @apply w-full h-full m-auto px-3 text-sm;
}
.dropdown-menu {
  @apply overflow-hidden min-w-[10rem] border border-c-layout rounded-xl bg-white;

  position: fixed;
  z-index: 100;
  box-shadow: 0 4px 13px rgba($color: #10355d, $alpha: 0.15);
}
.dropdown-menu ul {
  @apply p-gap-item;
  overflow: auto !important;
}
.dropdown-menu li {
  @apply p-gap-group rounded-md break-keep hover:bg-gray-100 cursor-pointer;
}
.dropdown-menu li:hover {
  background-color: #f1f1f1;
}
</style>
