<script setup>
import {ref, computed, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import utils from "@/common/utils";
import {CheckModule} from "@/modules";
import {groupStore, projectMembersStore, projectStore} from "@/stores";
// Components
import {toast} from "vue3-toastify";
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import UserContainer from "@/components/user-container.vue";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const {groupId, groupName} = storeToRefs(groupStore());
const {projectId, projectName} = storeToRefs(projectStore());

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});

const isLoading = ref(true);
const viewIndex = ref(0);
const tabIndex = ref(0);
const searchUserNameRef = ref();
const newUserNameRef = ref();
const newUserPhoneNumberRef = ref();
let tooltip = null;

function ModuleExistUsers() {
  const moduleCheck = ref(CheckModule());
  const receivers = ref([]);
  const existUsers = ref([]);
  const isLoading = ref(false);
  let tooltip = null;

  const checkState = computed(() => {
    return JSON.parse(JSON.stringify(moduleCheck.value.checkedList));
  });

  const clear = () => {
    existUsers.value = ref([]);
    if (tooltip) {
      tooltip.destory();
    }
  };

  const fetchSearchExternalUsers = async (keyword) => {
    // let validateName = utils.Validate.validateName(keyword, true);
    // if (!validateName.status) {
    //   tooltip = utils.Common.showTippy("#searchUserName", validateName.message, undefined, tooltip);
    //   return;
    // }

    try {
      isLoading.value = true;
      const res = await apis.group.getGroupAllSubmitters(groupId.value, keyword);
      existUsers.value = res.data.data;

      moduleCheck.value.initializeCheckedList(existUsers.value.length);
      isLoading.value = false;

      return true;
    } catch (error) {
      return false;
    }
  };

  const addUsers = (user) => {
    if (!user.cellPhone) {
      return;
    }

    const isExistingUser = receivers.value.some(
      (receiver) => receiver.cellPhone === user.cellPhone
    );
    if (!isExistingUser) {
      receivers.value.push({name: user.name, cellPhone: user.cellPhone});
    }
  };

  const removeUser = (item) => {
    receivers.value = receivers.value.filter((val) => val.cellPhone !== item.cellPhone);
  };

  const getCount = () => {
    return receivers.value.length;
  };

  return {
    receivers,
    existUsers,
    checkState,

    moduleCheck,

    clear,
    fetchSearchExternalUsers,
    addUsers,
    removeUser,
    getCount,
  };
}
const moduleExistUsers = ref(ModuleExistUsers());

function ModuleNewUsers() {
  const receivers = ref([]);
  let tooltip = null;

  const clear = () => {
    if (tooltip) {
      tooltip.destory();
    }
  };

  const addNewUser = (name, cellPhone) => {
    let validateName = utils.Validate.validateName(name, true);
    if (!validateName.status) {
      tooltip = utils.Common.showTippy("#newUserName", validateName.message, undefined, tooltip);
      return;
    }
    validateName = utils.Validate.validatePhone(cellPhone, true);
    if (!validateName.status) {
      tooltip = utils.Common.showTippy(
        "#newUserPhoneNumber",
        validateName.message,
        undefined,
        tooltip
      );
      return;
    }

    const isExistingUser = receivers.value.some((receiver) => receiver.cellPhone === cellPhone);
    if (isExistingUser) {
      tooltip = utils.Common.showTippy(
        "#newUserPhoneNumber",
        "이미 등록된 휴대전화번호가 있습니다",
        undefined,
        tooltip
      );
      return;
    }

    cellPhone = cellPhone.replace(/-/g, "");

    newUserNameRef.value.setFocus();
    newUserNameRef.value.setText("");
    newUserPhoneNumberRef.value.setText("");

    receivers.value.push({name: name, cellPhone: cellPhone});
  };

  const removeUser = (item) => {
    receivers.value.filter((val) => val.userId !== item.cellPhone);
  };

  const getCount = () => {
    return receivers.value.length;
  };

  return {
    receivers,

    clear,
    addNewUser,
    removeUser,
    getCount,
  };
}
const moduleNewUsers = ref(ModuleNewUsers());

const totalUsersCount = computed(() => {
  const count = moduleExistUsers.value.getCount() + moduleNewUsers.value.getCount();
  return count;
});

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(async () => {
  isLoading.value = false;

  setTimeout(() => {
    searchUserNameRef.value.setFocus();
  }, 100);
});

/**********************************************************
 * Event Handlers
 *********************************************************/
function closeModal(status) {
  emits("close", {status: status});
}

function handleTab(index) {
  tabIndex.value = index;

  if (0 === tabIndex.value) {
    moduleNewUsers.value.clear();
    setTimeout(() => {
      searchUserNameRef.value.setFocus();
    }, 100);
  } else if (1 === tabIndex.value) {
    moduleExistUsers.value.clear();
    setTimeout(() => {
      newUserNameRef.value.setFocus();
    }, 100);
  }
}

function handleAddNewUser() {
  moduleNewUsers.value.addNewUser(
    newUserNameRef.value.getText(),
    newUserPhoneNumberRef.value.getText()
  );
}

async function handleSearchUser() {
  const keyword = searchUserNameRef.value.getText();
  await moduleExistUsers.value.fetchSearchExternalUsers(keyword);
}

function handleChangeCellPhone(result) {
  newUserPhoneNumberRef.value.setText(
    utils.String.formatPhoneNumber(utils.String.onlyNumbers(result.value.trim()))
  );
}

async function handleAddAddressEntrys() {
  let entrys = [];
  entrys = entrys.concat(moduleExistUsers.value.receivers);
  entrys = entrys.concat(moduleNewUsers.value.receivers);

  if (!entrys.length) {
    toast.warn("등록할 인원을 추가 해주세요", {
      position: toast.POSITION.TOP_CENTER,
    });
    return;
  }

  try {
    const res = await apis.group.addAddressEntrys(
      groupId.value,
      props.params.addressBookId,
      entrys
    );
    closeModal(true);
  } catch (error) {}
}
</script>

<template>
  <div class="w-[37rem]">
    <div>
      <div class="modal-title">제출자 그룹 등록</div>
      <button class="modal-btn-close" @click="closeModal(false)">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content relative min-h-[20rem]">
      <template v-if="isLoading">
        <div
          class="absolute top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden flex flex-col items-center justify-center"
        >
          <div
            class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
          ></div>
        </div>
      </template>
      <template v-else>
        <template v-if="0 === viewIndex">
          <div class="">
            <!-- 탭 -->
            <div class="tab-container">
              <ul id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                <li role="presentation">
                  <button
                    id="tabExistUser"
                    :class="[0 === tabIndex ? 'tab-active' : '']"
                    type="button"
                    role="tab"
                    @click="handleTab(0)"
                  >
                    기존 인원
                  </button>
                </li>
                <li role="presentation">
                  <button
                    id="tabNewUser"
                    :class="[1 === tabIndex ? 'tab-active' : '']"
                    type="button"
                    role="tab"
                    @click="handleTab(1)"
                  >
                    신규 인원
                  </button>
                </li>
              </ul>
            </div>

            <!-- 탭뷰 -->
            <div id="myTabContent" class="bg-gray-50 p-4 rounded-lg">
              <!-- 기존 제출자 -->
              <div v-if="0 === tabIndex" role="tabpanel">
                <div class="flex items-center h-[3.4rem]">
                  <label-input
                    id="searchUserName"
                    ref="searchUserNameRef"
                    class="w-full"
                    type="text"
                    placeholder-text="이름 검색"
                    height="h-c-form-sm"
                    @enter="handleSearchUser"
                  >
                    <template v-slot:after>
                      <div class="w-10 m-auto">
                        <button tabindex="-1" @click="handleSearchUser">
                          <font-awesome-icon icon="magnifying-glass" class="text-lg" />
                        </button>
                      </div>
                    </template>
                  </label-input>
                  <div v-if="moduleExistUsers.existUsers.length" class="ml-gap-group h-full">
                    <button
                      class="cs-btn cs-btn-primary w-[4.5rem] h-full"
                      @click="moduleExistUsers.addUsers()"
                    >
                      추가
                    </button>
                  </div>
                </div>

                <div class="overflow-y-auto h-[16rem] max-h-[16rem] mt-gap-group relative">
                  <template v-if="moduleExistUsers.isLoading">
                    <div
                      class="absolute top-0 left-0 right-0 bottom-0 w-full h-full overflow-hidden flex flex-col items-center justify-center"
                    >
                      <div
                        class="loader-spinner ease-linear rounded-full border-4 border-gray-200 border-t-blue-500 h-12 w-12 mb-4"
                      ></div>
                    </div>
                  </template>
                  <template v-else>
                    <ul v-if="moduleExistUsers.existUsers.length" class="">
                      <li
                        v-for="(user, idx) in moduleExistUsers.existUsers"
                        :key="idx"
                        class="exist-user-row"
                        @click="moduleExistUsers.addUsers(user)"
                      >
                        <label
                          class="flex items-center w-full h-full cursor-pointer"
                          :for="'exist' + idx"
                        >
                          <font-awesome-icon icon="plus" class="mr-gap-group text-lg" />
                          <span class="w-full cs-ellipsis1">{{ user.name }}</span>
                          <span class="ml-gap-group min-w-[9rem] text-left text-c-normal">
                            {{ utils.String.formatPhoneNumber(user.cellPhone) }}
                          </span>
                        </label>
                      </li>
                    </ul>
                    <div v-else class="w-full mt-gap-group text-center text-c-dark">
                      검색된 제출자가 없습니다.
                    </div>
                  </template>
                </div>
              </div>
              <!-- 신규 제출자 -->
              <div v-if="1 === tabIndex" role="tabpanel">
                <div class="flex items-center">
                  <label-input
                    id="newUserName"
                    ref="newUserNameRef"
                    class="w-full"
                    type="text"
                    placeholder-text="이름"
                    :is-important="true"
                    height="h-c-form-sm"
                    @enter="handleAddNewUser"
                  >
                  </label-input>
                  <label-input
                    id="newUserPhoneNumber"
                    ref="newUserPhoneNumberRef"
                    class="w-full ml-gap-group"
                    type="text"
                    placeholder-text="휴대전화번호"
                    :is-important="true"
                    height="h-c-form-sm"
                    @change="handleChangeCellPhone"
                    @enter="handleAddNewUser"
                  >
                  </label-input>
                </div>
                <div class="">
                  <button
                    class="cs-btn cs-btn-primary w-full h-c-confirm mt-gap-group"
                    @click="handleAddNewUser"
                  >
                    추가
                  </button>
                </div>
              </div>
            </div>

            <div class="mt-gap-group border-t border-c-layout"></div>
          </div>

          <section class="mt-gap-group">
            <p class="">추가된 인원</p>
            <div class="overflow-y-scroll max-h-[300px] py-3">
              <div v-if="moduleExistUsers.getCount()" class="mt-gap-group">
                <p class="mb-2 text-sm text-c-normal">
                  기존 인원 ({{ moduleExistUsers.getCount() }})
                </p>
                <user-container
                  class=""
                  :items="moduleExistUsers.receivers"
                  :isCancel="true"
                  :show-sub-title="true"
                  @remove="moduleExistUsers.removeUser"
                >
                  <template #title="slotProps">
                    {{ slotProps.item.name }}
                  </template>
                  <template #sub="slotProps">
                    {{ utils.String.formatPhoneNumber(slotProps.item.cellPhone) }}
                  </template>
                </user-container>
              </div>
              <div v-if="moduleNewUsers.getCount()" class="mt-gap-group">
                <p class="mb-2 text-sm text-c-normal">
                  신규 인원 ({{ moduleNewUsers.getCount() }})
                </p>
                <user-container
                  class=""
                  :items="moduleNewUsers.receivers"
                  :isCancel="true"
                  :show-sub-title="true"
                  @remove="moduleNewUsers.removeUser"
                >
                  <template #title="slotProps">
                    {{ slotProps.item.name }}
                  </template>
                  <template #sub="slotProps">
                    {{ utils.String.formatPhoneNumber(slotProps.item.cellPhone) }}
                  </template>
                </user-container>
              </div>
            </div>
          </section>

          <div class="modal-footer">
            <button
              ref="submit"
              id="btnLinkNext"
              class="modal-confirm"
              @click="handleAddAddressEntrys"
            >
              <span class="text-white">등록</span>
            </button>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Scoped styles here */

.exist-user-row {
  @apply flex relative items-center h-[3.4rem] pl-gap-group pr-[3rem] border-b border-gray-100 rounded-lg cursor-pointer transition-all;

  &:hover {
    @apply bg-c-gray-light;

    // &::after {
    //   content: "+";
    //   position: absolute;
    //   top: 50%;
    //   right: 1rem;
    //   transform: translateY(-50%);
    // }
  }
}
</style>
