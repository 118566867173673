<script setup>
import utils from "@/common/utils.js";

const emits = defineEmits(["remove"]);
const props = defineProps({
  items: {
    type: Array,
  },
  isCancel: {
    type: Boolean,
    default: true,
  },
  showSubTitle: {
    type: Boolean,
    default: false,
  },
});

function handleRemove(item) {
  emits("remove", item);
}
</script>

<template>
  <ul class="flex flex-wrap gap-3">
    <li v-for="(item, idx) in props.items" :key="idx" class="badge-item">
      <div class="mr-gap-group">
        <template v-if="!props.showSubTitle">
          <slot name="single-title" :item="item"></slot>
        </template>
        <template v-else>
          <p class="leading-4">
            <span><slot name="title" :item="item"></slot></span>
          </p>
          <p class="mt-gap-text text-sm text-c-normal leading-4">
            <span><slot name="sub" :item="item"></slot></span>
          </p>
        </template>
      </div>
      <button v-if="isCancel" class="cs-btn-rounded cs-btn-gray-after" @click="handleRemove(item)">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.badge-item {
  @apply flex items-center h-[3.8rem] pl-3 pr-1 border border-c-thumb rounded-lg;
}
</style>
