import {ref, computed} from "vue";
import {defineStore} from "pinia";
import API from "@/apis";
import utils from "@/common/utils.js";
import {HttpStatusCode} from "axios";

export const groupsStore = defineStore("groups", () => {
  const groups = ref([]);

  /****************************************************
   * 그룹 생성
   ****************************************************/
  async function apiCreateNewGroup(name, domain) {
    name = name.trim().normalize();
    domain = domain.trim().normalize();

    try {
      const res = await API.group.createGroup(name, domain);
      if (HttpStatusCode.Ok === res.status) {
        window.location.href = utils.Common.createSubdomainUrl(domain);
        return;
      }

      return res;
    } catch (error) {
      utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      throw error;
    }
  }

  /****************************************************
   * 나의 그룹 리스트 불러오기
   ****************************************************/
  async function apiGetMyGroupList() {
    try {
      const res = await API.group.getGroupList();
      groups.value = res.data.data;

      return res;
    } catch (error) {
      utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      throw error;
    }
  }

  return {
    groups,

    // actions ----------------------------------------
    apiCreateNewGroup,
    apiGetMyGroupList,
  };
});
