import {ref, computed} from "vue";
import {defineStore} from "pinia";
import axios, {HttpStatusCode} from "axios";
import apis from "@/apis";
import utils from "@/common/utils";
import {groupStore, projectStore} from "@/stores";

export const commonStore = defineStore("common", () => {
  const zipDownloads = ref([]);

  async function apiZipDownloadForUsers(submitterUserIds) {
    const zipId = utils.Common.generateRandomNumber(5);

    try {
      const res = await apis.project.downloadDriveArchiveUsers(
        groupStore().groupId,
        projectStore().projectId,
        submitterUserIds,
        zipId,
        projectStore().projectName
      );

      commonStore().addZipDownload(res.data.data.zipId, res.data.data.zipName);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  async function apiZipDownloadForFiles(fileIds) {
    const zipId = utils.Common.generateRandomNumber(5);

    try {
      const res = await apis.project.downloadDriveArchiveFiles(
        groupStore().groupId,
        projectStore().projectId,
        fileIds,
        zipId,
        projectStore().projectName
      );

      commonStore().addZipDownload(res.data.data.zipId, res.data.data.zipName);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);
      }
      throw error;
    }
  }

  function addZipDownload(zipId, zipName) {
    zipDownloads.value.push({
      zipId: zipId,
      zipName: zipName,
    });
  }

  function removeZipDownload(zipId) {
    zipDownloads.value = zipDownloads.value.filter((zip) => zipId != zip.zipId);
  }

  return {
    zipDownloads,

    apiZipDownloadForUsers,
    apiZipDownloadForFiles,
    addZipDownload,
    removeZipDownload,
  };
});
