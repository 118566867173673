<script setup>
import {onMounted, ref, watch} from "vue";
import utils from "@/common/utils.js";

const props = defineProps({
  isFocus: {
    type: Boolean,
  },
  type: {
    type: String,
    default: "text",
  },
  placeholderText: {
    type: String,
    required: true,
  },
  initText: {
    type: String,
  },
  isImportant: {
    type: Boolean,
  },
  inputId: {
    type: String,
    default: () => {
      return utils.Common.generateRandomNumber(5);
    },
  },
  bgColor: {
    type: String,
    default: "bg-white",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: "h-10",
  },
  isDefaultType: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["change", "hidden", "focusin", "focusout", "enter"]);
defineExpose({setText, getText, setFocus});

const text = ref("");
const isPressed = ref(false);

onMounted(() => {
  text.value = props.initText;
});

watch(
  () => props.initText,
  () => {
    text.value = props.initText;
  }
);

function setText(value) {
  text.value = value;
}
function getText() {
  return text.value;
}
function setFocus() {
  const ele = document.getElementById("LabelInput" + props.inputId);
  if (ele) {
    ele.focus();
  }
}

function handleFocusIn(event) {
  text.value = event.target.value;
  emits("focusin", {
    value: text.value,
  });
}

function handleFocusOut(event) {
  text.value = event.target.value;
  emits("focusout", {
    value: text.value,
  });
}

function handleInput(event) {
  text.value = event.target.value;
  emits("change", {
    value: text.value,
  });
}

function handleEnter(event) {
  text.value = event.target.value;

  emits("enter", {
    value: text.value,
  });
}
function handleOutEnter(event) {
  isPressed.value = false;
}
</script>

<template>
  <label
    :for="'LabelInput' + props.inputId"
    class="relative flex overflow-hidden rounded-md border border-gray-200 shadow-sm transition-all focus-within:border-c-first-light"
    :class="[props.disabled ? 'bg-c-disabled' : props.bgColor]"
  >
    <slot name="before"></slot>

    <div class="relative w-full px-4 pt-5">
      <input
        :type="type"
        :id="'LabelInput' + props.inputId"
        placeholder="Email"
        class="peer w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none"
        :class="props.height"
        :autofocus="props.isFocus"
        :disabled="props.disabled"
        v-model="text"
        @focusin="handleFocusIn"
        @focusout="handleFocusOut"
        @input="handleInput"
        @keyup.enter="handleEnter"
      />

      <template v-if="!isDefaultType">
        <span
          class="absolute start-4 top-4 -translate-y-1/2 text-xs text-c-placeholder transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-4 peer-focus:text-xs"
          style="pointer-events: none"
        >
          {{ props.placeholderText }}
          <span v-if="props.isImportant" class="text-xs text-c-error peer-focus:hidden">*</span>
        </span>
      </template>
    </div>

    <slot name="after"></slot>
  </label>
</template>

<style lang="scss" scoped></style>
