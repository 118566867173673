<script setup>
import {ref, computed, onMounted, watch, onUnmounted} from "vue";
import {storeToRefs} from "pinia";
import {groupStore, projectStore, submitStore} from "@/stores";
import {useRouter, useRoute} from "vue-router";
import {RoutePaths} from "@/router/paths.js";
const router = useRouter();
const route = useRoute();
import utils from "@/common/utils";
import constants from "@/common/constants";
import {Modal, ModalComponents, DragDropModule, UploadModule} from "@/modules";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import RadialProgress from "vue3-radial-progress";
import axios, {HttpStatusCode} from "axios";

/**********************************************************
 * data
 *********************************************************/
const upload = ref(UploadModule());

const emits = defineEmits(["uploaded", "delete"]);
const props = defineProps({
  file: {
    type: Object,
    required: true,
  },
  inputFile: {
    type: Object,
  },
});

const isInProgress = computed(() => {
  return constants.PROGRESS_STATUS_TYPE.inprogress === upload.value.state.status ? true : false;
});

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(async () => {
  if (props.inputFile) {
    try {
      const file = await upload.value.uploadAttachedFile(props.inputFile);
      props.file.id = file.id;
      props.file.key = file.key;

      emits("uploaded", file);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const {groupMember} = storeToRefs(groupStore());

        if (HttpStatusCode.Forbidden === error.response.status) {
          let errorMessage = error.response.data.message;
          let confirmTitle = "플랜 업그레이드";
          let hiddenCancel = false;

          if (!groupMember.value || groupMember.value.role !== constants.USER_ROLE_TYPE.owner) {
            errorMessage =
              "팀의 플랜 사용량을 초과했습니다, 팀 관리자에게 플랜 업그레이드를 요청해주세요.";
            confirmTitle = "확인";
            hiddenCancel = true;
          }

          Modal.value.openModal(
            ModalComponents.ModalCancelConfirm,
            {
              hiddenCancel: hiddenCancel,
              confirmTitle: confirmTitle,
              html: `<div class="text-lg break-keep">${errorMessage}</div>`,
            },
            (result) => {
              if (result.status) {
                router.push({
                  name: RoutePaths.TeamSettingPayment.name,
                });
              } else {
                router.back();
              }
            }
          );
        }
      }

      emits("delete", props.file);
    }
  } else {
    upload.value.state.status = constants.PROGRESS_STATUS_TYPE.completed;
  }
});

/**********************************************************
 * Event Handlers
 *********************************************************/
function handleDelete() {
  emits("delete", props.file);
}
</script>

<template>
  <div
    class="flex relative items-center justify-between mb-gap-item p-gap-group rounded-md bg-gray-50 border border-c-layout"
  >
    <div v-if="isInProgress" class="radial">
      <radial-progress
        :diameter="30"
        :strokeWidth="3"
        :innerStrokeWidth="5"
        :innerStrokeColor="'#f7f7f7'"
        :startColor="'#0eeff2'"
        :stopColor="'#127aba'"
        :completed-steps="10"
        :total-steps="100"
      >
      </radial-progress>
    </div>
    <div v-else class="radial">
      <img
        class="w-icon-ext-sm h-icon-ext-sm mr-gap-item"
        :src="utils.Common.getFileIcon(props.file.ext)"
      />
    </div>
    <div class="flex items-center w-full ml-9 mr-5">
      <div class="w-full max-w-[40rem] cs-ellipsis1">
        {{ props.file.name }}
      </div>
      <div class="min-w-[5rem] text-c-normal">
        {{ utils.String.convertBytesToReadableSize(props.file.size) }}
      </div>
    </div>
    <button class="cs-btn cs-btn-rounded cs-btn-error-after flex" @click.stop="handleDelete">
      <font-awesome-icon icon="xmark" class="text-lg" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.radial {
  @apply absolute top-1/2 left-3 -translate-y-1/2;
}
</style>
