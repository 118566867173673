<script setup>
import {onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {projectsStore} from "@/stores";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
// Component
import {toast} from "vue3-toastify";
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import utils from "@/common/utils";
import {HttpStatusCode} from "axios";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const emits = defineEmits(["close"]);
const nameInputRef = ref();
const confirmButtonRef = ref();
let tooltip = null;

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(() => {
  nameInputRef.value.setFocus();
});

/**********************************************************
 * Event Handlers
 *********************************************************/
// 모달 닫기
function closeModal() {
  emits("close", {status: false});
}

// 프로젝트 생성
async function handleCreateProject(done) {
  const name = nameInputRef.value.getText();
  const validateName = utils.Validate.validateFileName(name);
  if (!validateName.status) {
    tooltip = utils.Common.showTippy("#nameInput", validateName.message, undefined, tooltip);
    done();
    return;
  }

  if (projectsStore().isExistProjectName(name)) {
    tooltip = utils.Common.showTippy(
      "#nameInput",
      "같은 이름의 제출함이 존재합니다",
      undefined,
      tooltip
    );
  } else {
    try {
      const res = await projectsStore().createProject(name);
      if (HttpStatusCode.Ok === res.status) {
        toast.info("제출함을 생성했습니다", {
          position: toast.POSITION.TOP_CENTER,
        });

        emits("close", {status: true, projectId: res.data.data.id});
      } else {
        const message =
          res.response.data || res.response.data.message
            ? res.response.data.message
            : "오류가 발생 했습니다, 잠시후 다시 시도해주세요.";
        tooltip = utils.Common.showTippy("#nameInput", message, undefined, tooltip);
      }

      done();
    } catch (error) {
      done();
    }
  }
}
</script>

<template>
  <div class="md:w-[30rem]">
    <div class="modal-title">제출함 만들기</div>
    <button class="modal-btn-close" @click="closeModal">
      <font-awesome-icon icon="xmark" class="text-lg" />
    </button>

    <div class="modal-content">
      <div class="">
        <label-input
          id="nameInput"
          ref="nameInputRef"
          height="h-c-form-md"
          type="text"
          placeholderText="제출함명"
          :isImportant="true"
        >
        </label-input>
      </div>

      <div class="mt-gap-section">
        <loader-button
          ref="confirmButtonRef"
          class="mt-gap-group"
          :customCSS="'modal-confirm'"
          @request="handleCreateProject"
        >
          확인
        </loader-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cs-content {
  @apply min-h-screen pt-[60px] pb-10 px-5;
  @apply rounded-t-sm;
  @apply bg-white;
}
</style>
