import axios, {HttpStatusCode} from "axios";
import {toast} from "vue3-toastify";
import utils from "@/common/utils";
import ErrorCodes from "@/common/system-message";
import constants from "@/common/constants";
import {RoutePaths} from "@/router/paths";
import {Modal, ModalComponents} from "@/modules";

const instance = axios.create({
  baseURL: `${import.meta.env.VITE_APP_BASE_URL}/api`,
  timeout: 300000,
  withCredentials: true,
});

initializeToken();

export const axiosAppData = {
  router: null,
  groupStore: null,
};

export function initializeToken() {
  const token = utils.Cookie.getCookie("token");
  if (token) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
}

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    utils.Common.csConsolelog(error, utils.Common.ConsolelogTypes.Error);

    if (!error.response) {
      alert("네트워크 에러");
      return Promise.reject(error);
    }

    const {status, data} = error.response;
    const urlObj = new URL(error.request.responseURL);
    const params = new URLSearchParams(urlObj.search);

    if (HttpStatusCode.Unauthorized === status) {
      handleUnauthorizedError(data);
    }
    if (HttpStatusCode.Forbidden === status) {
      handleForbiddenError(data);
    }
    if (HttpStatusCode.NotFound === status) {
      handleNotFoundError(data);
    }

    const p = params.get("noToast");
    if (!p) {
      setTimeout(() => {
        const message = data.message || data;
        toast.warn(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }, 200);
    }

    return Promise.reject(error);
  }
);

function handleUnauthorizedError(data) {
  if (ErrorCodes.unauthorized_email_auth.code === data.code) {
  } else {
    utils.Cookie.removeCookie("token");
    utils.Cookie.setCookie(constants.COOKIE_KEY.redirect, window.location.href);
    window.location.href = window.location.href;
  }
}

function handleForbiddenError(data) {
  if (ErrorCodes.forbidden_group.code === data.code) {
    alert("제출함 권한이 없습니다.");
    window.location.href = `${import.meta.env.VITE_APP_BASE_WEB_PROTOCOL}://www.${import.meta.env.VITE_APP_BASE_WEB_URL_PATH}`;
  } else if (ErrorCodes.forbidden_submitter_max_size.code === data.code) {
    let errorMessage = data.message;
    let confirmTitle = "플랜 업그레이드";
    let hiddenCancel = false;

    if (!axiosAppData.groupStore.groupMember.value) {
      errorMessage =
        "팀의 플랜 사용량을 초과했습니다, 팀 관리자에게 플랜 업그레이드를 요청해주세요.";
      confirmTitle = "확인";
      hiddenCancel = true;
    } else if (constants.USER_ROLE_TYPE.owner !== axiosAppData.groupStore.groupMember.value.role) {
      errorMessage =
        "팀의 플랜 사용량을 초과했습니다, 팀 관리자에게 플랜 업그레이드를 요청해주세요.";
      confirmTitle = "확인";
      hiddenCancel = true;
    }

    Modal.value.openModal(
      ModalComponents.ModalCancelConfirm,
      {
        hiddenCancel: hiddenCancel,
        confirmTitle: confirmTitle,
        html: `<div class="text-lg break-keep">${errorMessage}</div>`,
      },
      (result) => {
        if (result.status) {
          axiosAppData.router.push({
            name: RoutePaths.TeamSettingPayment.name,
          });
        } else {
          axiosAppData.router.back();
        }
      }
    );
  }
}

function handleNotFoundError(data) {
  if (ErrorCodes.not_found_user_cellPhone.code === data.code) {
    utils.Cookie.setCookie(constants.COOKIE_KEY.redirect, window.location.href, {
      expires: "120s",
    });

    const cellPhoneSettingUrl = utils.Common.getBaseWebUrl() + RoutePaths.CellPhone.path;
    window.location.href = cellPhoneSettingUrl;
  }
  if (ErrorCodes.not_found_group.code === data.code) {
    alert(data.message);
    window.location.href = utils.Common.getBaseWebUrl();
  }
}

export default instance;
