<script setup>
import {ref, computed, onMounted} from "vue";
import {storeToRefs} from "pinia";
import {HttpStatusCode} from "axios";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import {RoutePaths} from "@/router/paths";
import apis from "@/apis";
import utils from "@/common/utils";
import {userStore} from "@/stores";
// Components
import LabelInput from "@/components/label-input.vue";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
/**********************************************************
 * data
 *********************************************************/
const {
  userName,
  userEmail,
  userThumbnailUrl,
  userCellPhone,
  userIsAlarm,
  userIsEmailAlarm,
  userInActiveDays,
  userSnsProvider,
} = storeToRefs(userStore());

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});

// 이름
const nameInputRef = ref();
const nameSubmitRef = ref();
// 전화번호
const cellPhoneInputRef = ref();
const cellPhoneSubmitRef = ref();
// 탈퇴 이메일
const emailInputRef = ref();
const emailSubmitRef = ref();

let tooltip = null;

/**********************************************************
 * Methods
 *********************************************************/
onMounted(() => {
  nameInputRef.value.setText(userName.value);
  cellPhoneInputRef.value.setText(utils.String.formatPhoneNumber(userCellPhone.value).trim());
  alarmModule.value.isAlarm = userIsAlarm.value;
  alarmModule.value.isEmailAlarm = userIsEmailAlarm.value;
  alarmModule.value.inActiveDays = userInActiveDays.value;
});

/**********************************************************
 * 이름
 *********************************************************/
const nameModule = ref(NameModule());
function NameModule() {
  function handleEnter() {
    nameInputRef.value.onClickLoaderBtn();
  }

  async function handleSubmit(done) {
    const name = nameInputRef.value.getText().trim();
    const validateName = utils.Validate.validateName(name, true);
    if (!validateName.status) {
      tooltip = utils.Common.showTippy("#nameInput", validateName.message, undefined, tooltip);
      done();
    }

    const res = await userStore().apiUpdateUserData({name: name});
    userName.value = name;
    done();
    tooltip = utils.Common.showTippy("#nameInput", "변경되었습니다", undefined, tooltip);
  }

  return {
    handleEnter,
    handleSubmit,
  };
}

/**********************************************************
 * 휴대전화
 *********************************************************/
const cellPhoneModule = ref(CellPhoneModule());
function CellPhoneModule() {
  function handleChange(event) {
    const number = utils.String.formatPhoneNumber(utils.String.onlyNumbers(event.value.trim()));
    cellPhoneInputRef.value.setText(number.trim());
  }

  function handleEnter() {
    cellPhoneSubmitRef.value.onClickLoaderBtn();
  }

  async function handleSubmit(done) {
    let cellPhone = cellPhoneInputRef.value.getText().trim();
    const validateCellPhone = utils.Validate.validatePhone(cellPhone, true);
    if (!validateCellPhone.status) {
      tooltip = utils.Common.showTippy(
        "#cellPhoneInput",
        validateCellPhone.message,
        undefined,
        tooltip
      );
      done();
    }

    cellPhone = utils.String.formatSimplePhoneNumber(cellPhone);
    const res = await userStore().apiUpdateUserData({
      cellPhone: cellPhone,
      isAlarm: true,
    });
    userCellPhone.value = cellPhone;
    done();
    tooltip = utils.Common.showTippy("#cellPhoneInput", "변경되었습니다", undefined, tooltip);
  }

  return {
    handleChange,
    handleEnter,
    handleSubmit,
  };
}

/**********************************************************
 * 패스워드
 *********************************************************/
// const passwordData = ref(usePassword());
// function usePassword() {
//   const currentPassword = ref("");
//   const newPassword = ref("");
//   const confirmPassword = ref("");
//   const currentPasswordErrorMsg = ref("");
//   const newPasswordErrorMsg = ref("");
//   const confirmErrorMsg = ref("");

//   function validPw() {
//     currentPasswordErrorMsg.value = "";

//     const result = validatePassword(currentPassword.value);
//     if (!result.status) {
//       currentPasswordErrorMsg.value = result.message;
//     }
//   }

//   function validNewPw() {
//     newPasswordErrorMsg.value = "";

//     const result = validatePassword(newPassword.value);
//     if (!result.status) {
//       newPasswordErrorMsg.value = result.message;
//     }
//   }

//   function validConfirmPw() {
//     confirmErrorMsg.value = "";

//     if (newPassword.value !== confirmPassword.value) {
//       confirmErrorMsg.value = "비밀번호가 같지 않습니다";
//     }
//   }

//   function submit() {
//     if (
//       currentPasswordErrorMsg.value.length ||
//       newPasswordErrorMsg.value.length ||
//       confirmErrorMsg.value.length
//     ) {
//       setTimeout(() => {
//         refBtnPassword.value.onStopTime();
//       }, 1000);
//       return;
//     }

//     refBtnPassword.value.onStopTime();
//   }

//   const isDisabled = computed(() => {
//     if (
//       currentPasswordErrorMsg.value.length ||
//       newPasswordErrorMsg.value.length ||
//       confirmErrorMsg.value.length
//     ) {
//       return true;
//     }

//     if (
//       !currentPassword.value.length ||
//       !newPassword.value.length ||
//       !confirmPassword.value.length
//     ) {
//       return true;
//     }

//     return false;
//   });

//   return {
//     currentPassword,
//     newPassword,
//     confirmPassword,
//     currentPasswordErrorMsg,
//     newPasswordErrorMsg,
//     confirmErrorMsg,

//     validPw,
//     validNewPw,
//     validConfirmPw,
//     submit,

//     isDisabled,
//   };
// }

/**********************************************************
 * 알림
 *********************************************************/
const alarmModule = ref(AlarmModule());
function AlarmModule() {
  const isAlarm = ref("");
  const isEmailAlarm = ref("");
  const inActiveDays = ref([]);
  const inActiveStartTime = ref("");
  const inActiveEndTime = ref("");

  function changeAlarm() {
    userStore().apiUpdateUserData({isAlarm: isAlarm.value});
  }
  function changeEmailAlarm() {
    userStore().apiUpdateUserData({isEmailAlarm: isEmailAlarm.value});
  }
  function changeDays() {
    userStore().apiUpdateUserData({inActiveDays: inActiveDays.value});
  }

  return {
    isAlarm,
    isEmailAlarm,
    inActiveDays,
    inActiveStartTime,
    inActiveEndTime,

    changeAlarm,
    changeEmailAlarm,
    changeDays,
  };
}

/**********************************************************
 * 탈퇴 이메일
 *********************************************************/
const withdrawalEmailModule = ref(WithdrawalEmailModule());
function WithdrawalEmailModule() {
  async function handleSubmit(done) {
    let email = emailInputRef.value.getText();
    if (!email) {
      done();
      return;
    }

    email = email.trim();
    const validateEmail = utils.Validate.validateEmail(email);
    if (!validateEmail.status) {
      tooltip = utils.Common.showTippy("#emailInput", validateEmail.message, undefined, tooltip);
      done();
      return;
    }

    const res = await userStore().apiDeleteUserAccount(email);
  }

  return {
    handleSubmit,
  };
}

/**********************************************************
 * 모달 닫기
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}
</script>

<template>
  <div class="md:w-[40rem]">
    <div class="modal-title">내 프로필</div>
    <button class="modal-btn-close" @click="closeModal">
      <font-awesome-icon icon="xmark" class="text-lg" />
    </button>

    <div class="modal-content">
      <div class="overflow-scroll mt-5 px-5">
        <!-- 이메일 -->
        <div class="profile-wrapper">
          <h3 class="">이메일</h3>
          <div>{{ userEmail }}</div>
        </div>

        <!-- 이름 -->
        <div class="profile-wrapper mt-gap-section">
          <div class="w-full">
            <div class="flex items-center">
              <h3 class="">이름</h3>
              <div class="w-full">
                <label-input
                  id="nameInput"
                  ref="nameInputRef"
                  class="w-full"
                  type="text"
                  placeholder-text="이름"
                  :is-important="true"
                  height="h-c-form-sm"
                  @enter="nameModule.handleEnter"
                >
                  <template v-slot:after>
                    <div class="flex items-center justify-center pr-gap-item">
                      <loader-button
                        ref="cellPhoneSubmitRef"
                        :customCSS="'cs-btn cs-btn-line cs-btn-rounded w-[3rem] h-[2.8rem] p-c-btn-sm'"
                        @request="nameModule.handleSubmit"
                      >
                        변경
                      </loader-button>
                    </div>
                  </template>
                </label-input>
              </div>
            </div>
          </div>
        </div>

        <!-- 전화번호 -->
        <div class="profile-wrapper mt-gap-section">
          <div class="w-full">
            <div class="flex items-center">
              <h3 class="">휴대전화</h3>
              <div class="w-full">
                <label-input
                  id="cellPhoneInput"
                  ref="cellPhoneInputRef"
                  class="w-full"
                  type="text"
                  placeholder-text="휴대전화번호"
                  :is-important="true"
                  height="h-c-form-sm"
                  @enter="cellPhoneModule.handleEnter"
                  @change="cellPhoneModule.handleChange"
                >
                  <template v-slot:after>
                    <div class="flex items-center justify-center pr-gap-item">
                      <loader-button
                        ref="cellPhoneSubmitRef"
                        :customCSS="'cs-btn cs-btn-line cs-btn-rounded w-[3rem] h-[2.8rem] p-c-btn-sm'"
                        @request="cellPhoneModule.handleSubmit"
                      >
                        변경
                      </loader-button>
                    </div>
                  </template>
                </label-input>
              </div>
            </div>
          </div>
        </div>

        <!-- 비밀번호 -->
        <!-- <template v-if="!userSnsProvider || !userSnsProvider.length">
          <div class="profile-wrapper">
            <div class="w-full">
              <div class="w-full">
                <div class="flex items-center">
                  <h3 class="">현재 비밀번호</h3>
                  <div class="flex w-full">
                    <div class="cs-form cs-form_input w-full h-form">
                      <input
                        class=""
                        type="password"
                        placeholder="현재 비밀번호를 입력해주세요"
                        v-model="passwordData.currentPassword"
                        @input="passwordData.validPw"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex items-center">
                  <h3></h3>
                  <div class="h-6 pt-1">
                    <div
                      class="cs-feedback cs-feedback-error"
                      v-if="
                        passwordData.currentPasswordErrorMsg &&
                        passwordData.currentPasswordErrorMsg.length
                      "
                    >
                      <i class="fi fi-sr-comment-exclamation"></i
                      ><span>{{ passwordData.currentPasswordErrorMsg }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mt-3">
                <div class="flex items-center">
                  <h3 class="">새 비밀번호</h3>
                  <div class="flex w-full">
                    <div class="cs-form cs-form_input w-full h-form">
                      <input
                        class=""
                        type="password"
                        placeholder="비밀번호를 입력해주세요"
                        v-model="passwordData.newPassword"
                        @input="passwordData.validNewPw"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex items-center">
                  <h3></h3>
                  <div class="h-6 pt-1">
                    <div
                      class="cs-feedback cs-feedback-error"
                      v-if="
                        passwordData.newPasswordErrorMsg && passwordData.newPasswordErrorMsg.length
                      "
                    >
                      <i class="fi fi-sr-comment-exclamation"></i
                      ><span>{{ passwordData.newPasswordErrorMsg }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mt-3">
                <div class="flex items-center">
                  <h3 class="">비밀번호 재입력</h3>
                  <div class="flex w-full">
                    <div class="cs-form cs-form_input w-full h-form">
                      <input
                        class=""
                        type="password"
                        placeholder="비밀번호를 재입력 해주세요"
                        v-model="passwordData.confirmPassword"
                        @input="passwordData.validConfirmPw"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex items-center">
                  <h3></h3>
                  <div class="h-6 pt-1">
                    <div
                      class="cs-feedback cs-feedback-error"
                      v-if="passwordData.confirmErrorMsg && passwordData.confirmErrorMsg.length"
                    >
                      <i class="fi fi-sr-comment-exclamation"></i
                      ><span>{{ passwordData.confirmErrorMsg }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 text-right">
                <cs-button
                  ref="refBtnPassword"
                  :customCSS="'cs-btn cs-btn-line h-c-btn w-submit'"
                  :isDisabled="passwordData.isDisabled"
                  @click.stop="passwordData.submit"
                >
                  <slot><span class="text-sm">확인</span></slot>
                </cs-button>
              </div>
            </div>
          </div>

          <div class="profile-gap"></div>
        </template> -->

        <!-- 알림 -->
        <div class="profile-wrapper mt-gap-section">
          <div class="w-full">
            <div class="flex items-center">
              <h3 class="">알림 설정</h3>
              <!-- 알림 수신 설정 -->
              <div class="flex items-center justify-between1 w-full">
                <div class="flex items-center">
                  <div class="togglebtn r togglebtn-1" id="btnHideClientToClient">
                    <input
                      type="checkbox"
                      class="togglebtn-checkbox"
                      v-model="alarmModule.isEmailAlarm"
                      @change="alarmModule.changeEmailAlarm"
                    />
                    <div class="togglebtn-knobs"></div>
                    <div class="togglebtn-layer"></div>
                  </div>
                  <span class="ml-2 text-xs">이메일</span>
                </div>

                <div class="h-4 mx-5 border-l border-gray-300"></div>

                <div class="flex items-center">
                  <div class="togglebtn r togglebtn-1" id="btnHideClientToClient">
                    <input
                      type="checkbox"
                      class="togglebtn-checkbox"
                      v-model="alarmModule.isAlarm"
                      @change="alarmModule.changeAlarm"
                    />
                    <div class="togglebtn-knobs"></div>
                    <div class="togglebtn-layer"></div>
                  </div>
                  <span class="ml-2 text-md">카카오톡(알림톡)</span>
                </div>
              </div>
            </div>
            <div class="mt-gap-group border-t border-gray-100">
              <!-- 알림 요일 설정 -->
              <div class="mt-gap-group">
                <p class="">선택한 요일에는 알림을 받지 않습니다.</p>

                <ul class="flex overflow-x-auto h-10 mt-2">
                  <li
                    class="flex items-center"
                    v-for="(item, index) in alarmModule.inActiveDays"
                    :key="index"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="index"
                      v-model="item.state"
                      @change="alarmModule.changeDays"
                    />
                    <label class="ml-2 text-sm" :for="index">
                      {{ utils.String.convertDayToKo(item.day) }}
                    </label>
                    <div
                      v-if="index + 1 !== alarmModule.inActiveDays.length"
                      class="h-3 mx-2 md:mx-3 border-l border-gray-300"
                    ></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full mt-gap-section border-b border-gray-100"></div>

        <!-- 탈퇴 -->
        <div
          class="profile-wrapper mt-gap-section p-gap-group rounded-lg bg-red-50 border border-red-100"
        >
          <div class="w-full">
            <div class="flex items-center">
              <h3 class="text-c-error">회원탈퇴</h3>
              <div class="w-full">
                <label-input
                  id="emailInput"
                  ref="emailInputRef"
                  class="w-full"
                  type="text"
                  placeholder-text="이메일"
                  height="h-c-form-sm"
                  :is-important="true"
                >
                  <template v-slot:after>
                    <div class="flex items-center justify-center pr-gap-item">
                      <loader-button
                        ref="emailSubmitRef"
                        :customCSS="'cs-btn cs-btn-error cs-btn-rounded w-[3rem] h-[2.8rem] text-white'"
                        @request="withdrawalEmailModule.handleSubmit"
                      >
                        제거
                      </loader-button>
                    </div>
                  </template>
                </label-input>
              </div>
            </div>
            <validation-message
              class="mt-gap-group"
              :type="'error'"
              :texts="['계정의 정보가 삭제 됩니다, 제출함의 데이터는 삭제되지 않습니다.']"
            >
            </validation-message>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile-wrapper {
  @apply inline-block md:flex items-center;

  h3 {
    @apply min-w-[10rem];
  }
}
</style>
