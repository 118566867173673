<script setup>
import {ref, computed, onMounted, watch} from "vue";
import {storeToRefs} from "pinia";
import apis from "@/apis";
import utils from "@/common/utils";
import {userStore, groupStore, projectMembersStore, projectStore} from "@/stores";
import LoaderButton from "@/components/loader-button.vue";
import ValidationMessage from "@/views/components/common/validation-message.vue";
import UserContainer from "@/components/user-container.vue";
import LinkSharingExist from "@/views/modal/link-sharing/modal-project-link-sharing_exist.vue";
import LinkSharingNew from "@/views/modal/link-sharing/modal-project-link-sharing_new.vue";
import LinkSharingAddress from "@/views/modal/link-sharing/modal-project-link-sharing_address.vue";

/**********************************************************
 * Reactive Variables
 *********************************************************/
const {userName} = storeToRefs(userStore());
const {groupId, groupName} = storeToRefs(groupStore());
const {projectId} = storeToRefs(projectStore());

const emits = defineEmits(["close"]);
const props = defineProps({
  params: {
    type: Object,
  },
});

const viewIndex = ref(0);
const tabIndex = ref(0);
const linkSharingExistRef = ref();
const linkSharingNewRef = ref();
const linkSharingAddressRef = ref();

// 메세지
function ModuleComment() {
  const comment = ref("");
  let tooltip = null;

  const handleSubmit = async (done) => {
    const existUsers = linkSharingExistRef.value.getReceivers();
    const newUsers = linkSharingNewRef.value.getReceivers();
    const addressUsers = linkSharingAddressRef.value.getReceivers();

    const singleUsers = existUsers.concat(newUsers);
    const addressBookIds = addressUsers.map((val) => val.id);
    if (!singleUsers.length && !addressBookIds.length) {
      tooltip = utils.Common.showTippy("submitButton", "인원을 추가해주세요", null, tooltip);
      done();
      return;
    }

    let userSuccessed = false;
    let addressSuccessed = false;

    try {
      if (singleUsers.length) {
        const resUsers = await apis.project.addSubmitters(
          groupId.value,
          projectId.value,
          singleUsers,
          comment.value
        );
      }

      userSuccessed = true;
    } catch (error) {}

    try {
      if (addressBookIds.length) {
        const resAddress = await apis.project.addAddressBookSubmitters(
          groupId.value,
          projectId.value,
          addressBookIds,
          comment.value
        );
      }

      addressSuccessed = true;
    } catch (error) {}

    done();

    if (userSuccessed || addressSuccessed) {
      projectStore().apiLoadProjectDetails(projectId.value);
      projectMembersStore().apiGetSubmitters(groupId.value, projectId.value);

      closeModal();
    }
  };

  return {
    comment,

    handleSubmit,
  };
}
const moduleComment = ref(ModuleComment());

const totalUsersCount = computed(() => {
  let totalCount = 0;

  if (linkSharingExistRef.value && linkSharingNewRef.value) {
    const existUsers = linkSharingExistRef.value.getReceivers();
    const newUsers = linkSharingNewRef.value.getReceivers();
    totalCount = existUsers.length + newUsers.length;
  }

  return totalCount;
});

/**********************************************************
 * Lifecycle Hook
 *********************************************************/
onMounted(async () => {});

/**********************************************************
 * Event Handlers
 *********************************************************/
function closeModal() {
  emits("close", {status: false});
}

function handleTab(index) {
  tabIndex.value = index;

  if (0 === tabIndex.value) {
    linkSharingExistRef.value.fetchData();
    linkSharingNewRef.value.clear();
    linkSharingAddressRef.value.clear();
  } else if (1 === tabIndex.value) {
    linkSharingExistRef.value.clear();
    linkSharingAddressRef.value.clear();
  } else if (2 === tabIndex.value) {
    linkSharingAddressRef.value.fetchData();
    linkSharingNewRef.value.clear();
    linkSharingExistRef.value.clear();
  }
}

function back() {
  viewIndex.value = 0;
}

function next() {
  const totalCount =
    linkSharingExistRef.value.getReceivers().length +
    linkSharingNewRef.value.getReceivers().length +
    linkSharingAddressRef.value.getReceivers().length;
  if (!totalCount) {
    tooltip = utils.Common.showTippy(
      "#btnLinkNext",
      "초대할 인원 또는 그룹을 추가하셔야 합니다",
      undefined,
      tooltip
    );

    return;
  }

  viewIndex.value = 1;
}
</script>

<template>
  <div class="w-[37rem]">
    <div>
      <div class="modal-title">제출링크 보내기</div>
      <button class="modal-btn-close" @click="closeModal">
        <font-awesome-icon icon="xmark" class="text-lg" />
      </button>
    </div>

    <div class="modal-content relative min-h-[20rem]">
      <div v-show="0 === viewIndex">
        <section class="">
          <!-- 탭 -->
          <div class="tab-container">
            <ul id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li role="presentation">
                <button
                  id="tabExistUser"
                  :class="[0 === tabIndex ? 'tab-active' : '']"
                  type="button"
                  role="tab"
                  @click="handleTab(0)"
                >
                  기존 인원
                </button>
              </li>
              <li role="presentation">
                <button
                  id="tabGroup"
                  :class="[1 === tabIndex ? 'tab-active' : '']"
                  type="button"
                  role="tab"
                  @click="handleTab(1)"
                >
                  신규 인원
                </button>
              </li>
              <li role="presentation">
                <button
                  id="tabNewUser"
                  :class="[2 === tabIndex ? 'tab-active' : '']"
                  type="button"
                  role="tab"
                  @click="handleTab(2)"
                >
                  그룹 선택
                </button>
              </li>
            </ul>
          </div>

          <!-- 탭뷰 -->
          <div id="myTabContent" class="bg-gray-50 p-4 rounded-lg">
            <!-- 기존 제출자 -->
            <div v-show="0 === tabIndex" role="tabpanel">
              <link-sharing-exist ref="linkSharingExistRef"></link-sharing-exist>
            </div>
            <!-- 신규 제출자 -->
            <div v-show="1 === tabIndex" role="tabpanel">
              <link-sharing-new ref="linkSharingNewRef"></link-sharing-new>
            </div>
            <!-- 주소록 제출자 -->
            <div v-show="2 === tabIndex" role="tabpanel">
              <link-sharing-address
                ref="linkSharingAddressRef"
                @close="closeModal"
              ></link-sharing-address>
            </div>
          </div>
        </section>

        <div class="mt-gap-group border-t border-c-layout"></div>

        <section class="mt-gap-group">
          <p class="">추가된 인원</p>
          <validation-message
            v-if="!totalUsersCount"
            :texts="['링크를 전송할 인원를 추가 해주세요.']"
            :textAlign="'left'"
            :type="'info'"
          ></validation-message>
          <div class="overflow-y-scroll h-[10rem] max-h-[30rem] py-gap-group">
            <div
              v-if="linkSharingExistRef && linkSharingExistRef.getReceivers().length"
              class="mt-gap-group"
            >
              <p class="mb-2 text-md text-c-normal">
                기존 인원 ({{ linkSharingExistRef.getReceivers().length }})
              </p>
              <user-container
                class=""
                :items="linkSharingExistRef.getReceivers()"
                :isCancel="true"
                :show-sub-title="true"
                @remove="linkSharingExistRef.removeUser"
              >
                <template #title="slotProps">
                  {{ slotProps.item.name }}
                </template>
                <template #sub="slotProps">
                  {{ utils.String.formatPhoneNumber(slotProps.item.cellPhone) }}
                </template>
              </user-container>
            </div>
            <div
              v-if="linkSharingNewRef && linkSharingNewRef.getReceivers().length"
              class="mt-gap-group"
            >
              <p class="mb-2 text-md text-c-normal">
                신규 인원 ({{ linkSharingNewRef.getReceivers().length }})
              </p>
              <user-container
                class=""
                :items="linkSharingNewRef.getReceivers()"
                :isCancel="true"
                :show-sub-title="true"
                @remove="linkSharingNewRef.removeUser"
              >
                <template #title="slotProps">
                  {{ slotProps.item.name }}
                </template>
                <template #sub="slotProps">
                  {{ utils.String.formatPhoneNumber(slotProps.item.cellPhone) }}
                </template>
              </user-container>
            </div>
            <div
              v-if="linkSharingAddressRef && linkSharingAddressRef.getReceivers().length"
              class="mt-gap-group"
            >
              <p class="mb-2 text-md text-c-normal">
                그룹 ({{ linkSharingAddressRef.getReceivers().length }})
              </p>
              <user-container
                class=""
                :items="linkSharingAddressRef.getReceivers()"
                :isCancel="true"
                :show-sub-title="true"
                @remove="linkSharingAddressRef.removeUser"
              >
                <template #title="slotProps">
                  {{ slotProps.item.name }}
                </template>
                <template #sub="slotProps"> ({{ slotProps.item.entryCount }}) </template>
              </user-container>
            </div>
          </div>
        </section>

        <div class="modal-footer">
          <button class="w-full h-c-confirm" @click="closeModal">
            <span class="text-c-normal text-lg font-semibold">취소</span>
          </button>
          <span class="mx-5 w-[1px] h-10"></span>
          <button ref="submit" id="btnLinkNext" class="modal-confirm" @click="next">
            <span class="text-white">다음</span>
          </button>
        </div>
      </div>
      <div v-show="1 === viewIndex">
        <div class="p-gap-group bg-c-disabled">
          <div class="rounded-t-lg px-5 py-4 bg-c-kakao-yellow text-c-kakao font-medium">
            알림톡
          </div>
          <div class="px-6 py-6 rounded-b-lg bg-white">
            <p class="text-md">보낸사람: {{ userName }}</p>
            <p class="mt-1 text-md">받는사람: [선택한 제출자]</p>
            <p class="mt-1 text-md">
              "{{ groupName }}팀의 {{ props.params.project.name }}"에 대한 자료제출을 요청 드립니다.
            </p>
            <br />
            <p class="text-md">제출내용:</p>

            <div class="cs-form cs-form_textarea">
              <textarea
                class="w-full h-[8rem]"
                placeholder="800자 이하의 내용을 입력해주세요."
                maxlength="800"
                v-model="moduleComment.comment"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="w-full h-c-submit" @click="back">
            <span class="text-c-normal font-semibold">뒤로</span>
          </button>
          <span class="mx-5 w-[1px] h-10"></span>
          <loader-button
            id="submitButton"
            ref="submitButtonRef"
            class="modal-confirm"
            @request="moduleComment.handleSubmit"
          >
            <span class="text-white">보내기</span>
          </loader-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
