<script setup>
import {ref, onMounted} from "vue";
import {storeToRefs} from "pinia";
import {useRouter, useRoute} from "vue-router";
const router = useRouter();
const route = useRoute();
import {RoutePaths} from "@/router/paths";
import utils, {Icons} from "@/common/utils";
import {groupStore, projectStore, projectMembersStore} from "@/stores";

const {groupId, groupName} = storeToRefs(groupStore());
const {projectId, projectName, projectRulesCount} = storeToRefs(projectStore());

const emits = defineEmits(["onGoSubmitter"]);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

function handleDownload() {}

function handleGo() {
  emits("onGoSubmitter", {projectId: projectId.value, submitterId: props.item.userId});
}
</script>

<template>
  <div class="flex items-center w-full p-gap-group">
    <div class="flex items-center w-full">
      <img
        class="w-icon-ext-sm h-icon-ext-sm mr-gap-item"
        :src="utils.Common.getFileIcon(props.item.ext)"
      />
      <a class="file-name py-gap-text text-md" @click.stop="handleDownload"
        >{{ props.item.name.normalize() }}
      </a>
    </div>
    <div class="min-w-[10rem] max-w-[10rem] text-right">
      <div class="text-c-dark text-md cs-ellipsis1">{{ props.item.userName }}</div>
    </div>
    <div class="flex items-center justify-end min-w-[5rem] max-w-[5rem]">
      <button class="cs-btn cs-btn-rounded cs-btn-primary-after" @click="handleGo">
        <font-awesome-icon icon="angle-right" class="" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
