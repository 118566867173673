<script setup>
import {computed} from "vue";

const props = defineProps({
  texts: {
    type: Array,
  },
  textAlign: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: "warn",
  },
  isShowIcon: {
    type: Boolean,
    default: true,
  },
});

const textAlign = computed(() => {
  if ("left" === props.textAlign) {
    return "justify-start";
  } else if ("center" === props.textAlign) {
    return "justify-center";
  } else if ("right" === props.textAlign) {
    return "justify-end";
  }
});

const textColor = computed(() => {
  if ("info" === props.type) {
    return "text-c-info";
  } else if ("warn" === props.type) {
    return "text-c-warn";
  } else if ("error" === props.type) {
    return "text-c-error";
  } else if ("desc" === props.type) {
    return "text-c-normal";
  } else {
    return "text-c-normal";
  }
});
</script>

<template>
  <div class="min-h-[0.5rem] mt-2 flex items-start" :class="[textAlign, textColor]">
    <div v-if="props.isShowIcon" class="w-[1.2rem] mr-gap-text">
      <font-awesome-icon icon="circle-exclamation" class="text-lg" />
    </div>
    <div style="margin-top: 1px">
      <p
        v-for="(text, idx) in props.texts"
        :key="idx"
        class="text-md"
        :class="[0 < idx ? 'mt-gap-text' : '']"
        style="font-weight: 400"
      >
        {{ text }}
      </p>
    </div>
    <slot></slot>
  </div>
</template>
